import React, { useState } from 'react'

import {
    keepPreviousData,
    useQuery,
} from '@tanstack/react-query'

import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    ColumnFiltersState,
    getFilteredRowModel,
    SortingState,
    getSortedRowModel,
} from '@tanstack/react-table'

import { useUserStore } from '@/core/UserStore'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '@/core/firebase'
import { Shipment, ShipmentsTableHeader } from '@shared/ShipmentTypes'
import { UpdateShipmentsStatusesResponse } from '@shared/ResponseTypes'
import { updateShipmentsStatuses } from '@/api/shipments/updateShipment'

import {
    rankItem,
} from '@tanstack/match-sorter-utils'

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import { statusIconMapping, statusStyleMapping } from "@/lib/statusMapping";
import { trackShipment, voidShipment } from "@/api/shipments/updateShipment";
import { toast } from "@/components/ui/use-toast";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { getShipmentByID } from "@/api/shipments/getShipment";
import dayjs from "dayjs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { PickupData, PickupStatus } from "@shared/PickupTypes";
import { pickupStatusIconMapping, pickupStatusStyleMapping } from "@/lib/statusMapping";
import { copyableColumn } from '../shipments/ShipmentsTable'
import { capitalizeFirstLetter } from '@/lib/utils'
// import { EditPickupDialog } from "@/components/composite/Cards/PickupDetails";

import { Input } from '@/components/ui/input'
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { CheckIcon, MixerHorizontalIcon, RowsIcon } from "@radix-ui/react-icons";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronLeft, ChevronRight, ChevronsRight, ChevronsLeft, PackagePlus, RotateCw, MoreHorizontal, ChevronsUpDown, Copy, AlertTriangle  } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";


const startOfToday = dayjs().startOf("day").valueOf();

export type PickupsTableHeader = {
    id: string;
    carrier: PickupData["carrier"];
    readyTime: string;
    closeTime: string;
    status: PickupStatus;
};

export const PickupColumns = (handleOpenEditPickup, handleCancelPickup): ColumnDef<PickupData>[] => [
    {
        accessorKey: "id",
        header: "ID",
        cell: ({ cell }) => copyableColumn({ cell })
    },
    {
        accessorKey: "carrier",
        header: ({ column }) => {
            return (
                <Button className="ml-[-1rem]" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Carrier
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            return (
                <>
                    <Badge className="bg-gray-700">{row.original.carrier}</Badge>
                </>
            );
        }
    },
    {
        accessorKey: "name",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Sender's Name
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{row.original.shipper?.attentionName}</div>;
        },
    },
    {
        accessorKey: "date",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Date and Time
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{`${dayjs(row.original.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D YYYY")} | ${dayjs(row.original.pickupDetails.readyTimeStamp?.isoTimeStamp).format("hh:mm A")} to ${dayjs(row.original.pickupDetails.closeTimeStamp?.isoTimeStamp).format("hh:mm A")}`}</div>;
        },
        filterFn: (row, columnId, filterValue) => {
            // Not needed since we have the filter in the fetch
            // if (filterValue === "Upcoming" && row.original.pickupDetails.closeTimeStamp?.isoTimeStamp) {
            //     if (row.original.id === "PICKUP-8B8F3") {
            //         debugger;
            //     }
            //     const pickupTime = new Date(row.original.pickupDetails.closeTimeStamp?.isoTimeStamp).getTime();
            //     return pickupTime >= startOfToday; // Only include future pickups
            // }

            return true; // Show all pickups if no filter is applied
        }
    },
    // We combined these into the "Date and Time" column to save on table size spacing for smaller screens to prevent horizontal scroll
    // {
    //     accessorKey: "readyTime",
    //     header: ({ column }) => {
    //         return (
    //             <div className="ml-[-0.25rem]">
    //                 Ready Time
    //             </div>
    //         );
    //     },
    //     cell: ({ row }) => {
    //         return <div>{dayjs(row.original.pickupDetails.readyTimeStamp?.isoTimeStamp).format("hh:mm A")}</div>;
    //     },
    // },
    // {
    //     accessorKey: "closeTime",
    //     header: ({ column }) => {
    //         return (
    //             <div className="ml-[-0.25rem]">
    //                 Close Time
    //             </div>
    //         );
    //     },
    //     cell: ({ row }) => {
    //         return <div>{dayjs(row.original.pickupDetails.closeTimeStamp?.isoTimeStamp).format("hh:mm A")}</div>;
    //     },
    // },
    {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
            return (
                <>
                    <div className={"flex items-center " + pickupStatusStyleMapping[row.original.status || "N/A"]}>
                        {pickupStatusIconMapping[row.original.status || "N/A"]}
                        {row.original.status}
                    </div>
                </>
            );
        }
    },
    {
        accessorKey: "data",
        header: ({ column }) => {
            return (
                <div>Carrier Confirmation #</div>
            );
        },
        cell: ({ cell }: { cell: any }) => {
            const [copy, setCopy] = useState(false);
        
            return (
                <div className="flex items-center" onMouseEnter={() => setCopy(true)} onMouseLeave={() => setCopy(false)}>
                    <span>{cell.getValue()}</span>
                    <Copy
                        className={`ml-3 h-3 text-gray-500 ${copy ? "hover:text-primary" : "invisible"}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(cell.getValue() as string);
                            toast({
                                description: `${cell.getValue()} copied to clipboard.`
                            });
                        }}
                    />
                </div>
            );
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const handleEdit = () => {
                handleOpenEditPickup(row.original)
            }
            const handleCancel = () => {
                handleCancelPickup(row.original)
            }
            return (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Actions</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {/* <DropdownMenuItem onClick={handleEdit} disabled={row.getValue("status") === "Cancelled"}>
                                Edit
                            </DropdownMenuItem> */}
                            <DropdownMenuItem onClick={handleCancel} disabled={row.getValue("status") === "Cancelled"}>
                                Cancel
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    {/* // TODO: find a way to use EditPickupDialog with multiple shipments */}
                    {/* {pickupData && <EditPickupDialog setOpen={setEditDialogOpen} open={editDialogOpen} />} */}
                </>
            );
        }
    }
];


// A typical debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}

export function PickupsTable({ pickups, handleOpenEditPickup, handleCancelPickup, pageSize = 10 }) {
    const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: false }]);
    const user = useUserStore((state) => state.user);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState({}) // reference used to trigger data refresh

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize,
    })
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [{ id: "date", value: "Upcoming" }]
    )

    

    async function fetchMyData(options: {
        pageIndex: number
        pageSize: number
    }, filters: ColumnFiltersState, sorting: SortingState) {
        if (user) {
            try {
                const pickupsQuery = query(collection(db, "pickups"), where("uid", "==", user?.email));
            
                const pickupsSnapshot = await getDocs(pickupsQuery);

                let pickups: PickupData[] = []
                
                pickupsSnapshot.forEach((doc) => {
                    const data = doc.data() as PickupData

                    pickups.push({
                        ...data,
                        // TODO: using capitalizeFirstLetter because was storing lowercase "cancelled", update db and remove this line
                        status: data.status ? capitalizeFirstLetter(data.status) as PickupStatus : "Scheduled",
                    });

                });

                if (sorting.length === 0 || sorting[0].id === "date") {
                    // Sorting by status first, then date
                    pickups.sort((a, b) => {
                        const statusOrder = { "Scheduled": 1, "Cancelled": 2 };
                        const statusA = a.status ? statusOrder[a.status] : 3;
                        const statusB = b.status ? statusOrder[b.status] : 3;

                        if (statusA !== statusB) {
                            return statusA - statusB; // Sort by status order
                        }

                        const dateA = a.pickupDetails?.closeTimeStamp?.isoTimeStamp 
                            ? new Date(a.pickupDetails.closeTimeStamp.isoTimeStamp).getTime() 
                            : 0;
                        const dateB = b.pickupDetails?.closeTimeStamp?.isoTimeStamp 
                            ? new Date(b.pickupDetails.closeTimeStamp.isoTimeStamp).getTime() 
                            : 0;

                        return sorting.length === 0 || sorting[0].desc ? dateB - dateA : dateA - dateB; // Sort by date
                    });
                } else if (sorting[0].id === "carrier") {
                    if (sorting[0].desc) {
                        pickups.sort((a, b) => b.carrier.localeCompare(a.carrier));
                    } else {
                        pickups.sort((a, b) => a.carrier.localeCompare(b.carrier));
                    }
                } else if (sorting[0].id === "name") {
                    if (sorting[0].desc) {
                        pickups.sort((a, b) => b.shipper!.attentionName.localeCompare(a.shipper!.attentionName));
                    } else {
                        pickups.sort((a, b) => a.shipper!.attentionName.localeCompare(b.shipper!.attentionName));
                    }
                }
                

                if (filters.length > 0) {
                    for (const filter of filters) {
                        if (filter.id === "status" && filter.value !== "") {
                            pickups = pickups.filter(pickup => pickup[filter.id] === filter.value);       
                        } else if (filter.id === "id" && filter.value !== "") {
                            pickups = pickups.filter(pickup => {
                                // https://tanstack.com/table/latest/docs/framework/react/examples/filters-fuzzy
                                const itemRank = rankItem(pickup.id, filter.value as string)
        
                                // Return if the item should be filtered in/out
                                return itemRank.passed
                            })
                        } 
                        else if (filter.id === "date" && filter.value !== "") {
                            // Filter out pickups in the past
                            pickups = pickups.filter(p => {
                                const pickupTime = p.pickupDetails?.closeTimeStamp?.isoTimeStamp 
                                    ? new Date(p.pickupDetails.closeTimeStamp.isoTimeStamp).getTime() 
                                    : 0;
                                return pickupTime >= startOfToday; // Ignore past pickups
                            });
                        }
                    }
                }

                let paginatedPickups = pickups.slice(
                    options.pageIndex * options.pageSize,
                    (options.pageIndex + 1) * options.pageSize
                )

                return {
                    rows: paginatedPickups,
                    pageCount: Math.ceil(pickups.length / options.pageSize),
                    rowCount: pickups.length,
                }
                
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }

        return {
            rows: [],
            pageCount: 0,
            rowCount: 0,
        }
    }
    const columns = PickupColumns(handleOpenEditPickup, handleCancelPickup)

    const dataQuery = useQuery({
        queryKey: ['data', pagination, columnFilters, user, pickups, refresh, sorting],
        queryFn: () => fetchMyData(pagination, columnFilters, sorting),
        placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
    })

    const defaultData = React.useMemo(() => [], [])


    const table = useReactTable({
        data: dataQuery.data?.rows ?? defaultData,
        columns,
        // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
        rowCount: dataQuery.data?.rowCount, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
        state: {
            pagination,
            columnFilters,
            sorting,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),


        manualPagination: true, //we're doing manual "server-side" pagination
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        // debugTable: true,
    })


    return (
        <div className="p-2">
            <div className="flex items-center justify-between pb-4 ">
                <DebouncedInput
                    type="text"
                    value={(table.getColumn("id")?.getFilterValue() ?? '') as string}
                    onChange={value => {
                        table.setPageIndex(0)
                        table.getColumn("id")?.setFilterValue(value)
                    }}
                    placeholder={`Search by ID...`}
                    className="w-36 border shadow rounded"
                />
                <div className="flex space-x-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="hidden h-8 lg:flex">
                                <RowsIcon className="mr-2 h-4 w-4" />
                                Date Filter
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-[200px]">
                            {["", "Upcoming"].map((dateFilter) => (
                                <DropdownMenuItem
                                    key={dateFilter}
                                    onClick={() => {
                                        table.setPageIndex(0);
                                        table.getColumn("date")?.setFilterValue(
                                            dateFilter === "Upcoming" ? "Upcoming" : ""
                                        );
                                    }}
                                    className="flex justify-between"
                                >
                                    {dateFilter === "" ? "All" : dateFilter}
                                    {table.getColumn("date")?.getFilterValue() === dateFilter && (
                                        <CheckIcon className="h-4 w-4" />
                                    )}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="hidden h-8 lg:flex"> {/* Fixed width */}
                                <RowsIcon className="mr-2 h-4 w-4" />
                                Status Filter
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-[200px]">
                            {["", "Scheduled", "Cancelled"].map((status) => (
                                <DropdownMenuItem
                                    key={status}
                                    onClick={() => {
                                        table.setPageIndex(0)
                                        table.getColumn("status")?.setFilterValue(status)
                                    }}
                                    className="flex justify-between"
                                >
                                    {status === "" ? "All" : status}
                                    {table.getColumn("status")?.getFilterValue() === status && <CheckIcon className="h-4 w-4" />}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto hidden h-8 lg:flex"
                        disabled={dataQuery.isFetching}
                        onClick={async () => {
                            setRefresh({v: Date.now()})
                        }}>
                        <RotateCw className={`mr-2 h-4 w-4 ${dataQuery.isFetching ? " animate-spin" : ""}`} />
                        Refresh
                    </Button>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
                                <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                                View
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-[150px]">
                            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {table
                                .getAllColumns()
                                .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
                                .map((column) => {
                                    return (
                                        <DropdownMenuCheckboxItem key={column.id} className="capitalize" checked={column.getIsVisible()} onCheckedChange={(value) => column.toggleVisibility(!!value)}>
                                            {column.id === "customerName" ? "Name" : column.id === "id" ? "ID" : column.id === "trackingNumber" ? "Tracking #" : column.id}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return <TableHead key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</TableHead>;
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getFilteredRowModel().rows?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className=" h-24 text-center">
                                        <p className="text-lg">You have no pickups..</p>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                table.getFilteredRowModel().rows.map((row, index) => (
                                    <TableRow key={index} data-state={row.getIsSelected() && "selected"}>
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="text-muted-foreground flex-1 text-sm">{/* {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s) selected. */}</div>
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            const newPageSize = Number(value);
                            table.setPageSize(newPageSize);
                            table.setPageIndex(0);
                        }}>
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={table.getState().pagination.pageSize} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </div>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.setPageIndex(0);
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <span className="sr-only">Go to first page</span>
                    <ChevronsLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.previousPage();
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <ChevronLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.nextPage();
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to next page</span>
                    <ChevronRight className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        const lastPage = table.getPageCount() - 1;
                        return table.setPageIndex(lastPage);
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to last page</span>
                    <ChevronsRight className="h-4 w-4" />
                </Button>
            </div>
        </div>
    )
}


