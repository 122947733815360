import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Address } from "@shared/ShipmentTypes";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Info } from "lucide-react";

export default function AddressConfirmation({ type, setSelectedAddress, selectedRateCarrier, shipmentDetailsEntity, validationResult }) {
    const required = validationResult[selectedRateCarrier]?.required

    let title = ""
    if (type === "shipper") {
        title = required ? "Required Shippers Address Update" : "Shippers Address Confirmation"
    } else if (type === "receiver") {
        title = required ? "Required Receivers Address Update" : "Receivers Address Confirmation"
    } else {
        return <>Address Confirmation Type {type} Not Supported</> // unsupported
    }

    const conditionalColorClassName = `mt-4 p-4 ${required ? "bg-red-100" : ""}`;
    const customStyle = !required ? { backgroundColor: "#FEDF89" } : {};

    const renderZipCodeInfo = (postalCode) => {
        const zipPlus4Regex = /-\d{4}$/;
        if (zipPlus4Regex.test(postalCode)) {
            return (
                <TooltipProvider delayDuration={0}>
                    <Tooltip>
                        <TooltipTrigger>
                            <Info className="inline h-4 align-middle" />
                        </TooltipTrigger>
                        <TooltipContent className="w-72 text-left">
                            <p>
                                The 5-digit ZIP code is sufficient for most shipments to the US, but adding the ZIP+4 code enhances accuracy, improves delivery efficiency, and reduces errors. To verify this address, you can use the 
                                <a
                                    href="https://tools.usps.com/zip-code-lookup.htm?byaddress"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 underline"
                                >
                                    {" USPS ZIP Code Lookup"}
                                </a>.
                            </p>

                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        return null;
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-base font-medium">
                    {title}
                </CardTitle>
                <CardDescription className="mr-20 text-xs">
                    {required ? "The carrier requires that you choose the resolved address." : "The carrier prefers that you choose one of the resolved addresses for better delivery accuracy."}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <RadioGroup
                    className="space-y-4"
                    defaultValue="original" // Default to the shipper's original address
                    onValueChange={(selectedValue) => {
                        if (selectedValue !== "original") {
                            setSelectedAddress(
                                validationResult[selectedRateCarrier]?.resolvedAddresses[parseInt(selectedValue)]
                            );
                        } else {
                            setSelectedAddress(shipmentDetailsEntity?.address)
                        }
                    }}
                >
                    <Card className="p-4 border">
                        <label className="flex items-center gap-4 cursor-pointer">
                            <RadioGroupItem value="original" className="flex-shrink-0" />
                            <div className="text-sm text-gray-700">
                                {`${shipmentDetailsEntity?.address.street}, ${shipmentDetailsEntity?.address.line2 ? shipmentDetailsEntity?.address.line2 + ", " : ""}${shipmentDetailsEntity?.address.city}, ${shipmentDetailsEntity?.address.stateCode} ${shipmentDetailsEntity?.address.postalCode}`}
                                {renderZipCodeInfo(shipmentDetailsEntity?.address.postalCode)}
                                <span className="block text-xs text-gray-500">Original Address</span>
                            </div>
                        </label>
                    </Card>
                    <Card className={conditionalColorClassName} style={customStyle}>
                        <CardTitle className="text-base font-medium">
                            Resolved Addresses {!required && " (optional)"}
                        </CardTitle>
                        {validationResult[selectedRateCarrier]?.resolvedAddresses?.map((address: Address, index: number) => (
                            <Card
                                key={index}
                                className="mt-4 p-4 border cursor-pointer hover:shadow-sm"
                            >
                                <label className="flex items-center gap-4">
                                    <RadioGroupItem value={index.toString()} className="flex-shrink-0" />
                                    <div className="text-sm text-gray-700">
                                        {`${address.street}, ${address.line2 ? address.line2 + ", " : ""}${address.city}, ${address.stateCode} ${address.postalCode}`}
                                        {renderZipCodeInfo(address.postalCode)}
                                        <span className="block text-xs text-gray-500">{required && "Required "}Resolved Address</span>
                                    </div>
                                </label>
                            </Card>
                        ))}
                    </Card>
                </RadioGroup>
            </CardContent>
        </Card>
    )
}