import axios from "axios";
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc, deleteDoc, getDocs, query, collection, where } from "firebase/firestore";
import { db } from "@/core/firebase";
import { Shipment } from "@shared/ShipmentTypes";
import { getPickupByID, getPickupByShipmentID } from "./getPickup";
import { createPickup } from "./createPickup";
import { PickupData } from "@shared/PickupTypes";
import { getShipmentByID } from "../shipments/getShipment";
import dayjs from "dayjs";

type CancelResponseType = "Pickup cancelled" | "Pickup not cancelled" | "Pickup already cancelled";

const cancelPickup = async (pickupData: PickupData): Promise<CancelResponseType> => {
    if (pickupData?.carrier === "UPS") {
        return await cancelUPSPickup(pickupData.data);
    }
    if (pickupData?.carrier === "Purolator") {
        return await cancelPurolatorPickup(pickupData.data);
    }
    if (pickupData?.carrier === "FedEx") {
        return await cancelFedExPickup(pickupData);
    }
    if (pickupData?.carrier === "GLS") {
        return await cancelGLSPickup(pickupData.data);
    } else {
        throw "Unable to determine carrier for pickup cancellation";
    }
};

const cancelFedExPickup = async (pickupData: PickupData): Promise<CancelResponseType> => {
    try {
        const payload = {
            PickupDate: dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("YYYY-MM-DD"),
            PickupCode: pickupData.data
            // PickupLocation: pickupData.data.location
        };

        const response = await axios.put(`${import.meta.env.VITE_API_URL}/FedEx/cancel-pickup/`, payload);

        if (!response.data.errors) {
            return "Pickup cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const cancelGLSPickup = async (ID: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/GLS/cancel-pickup/${ID}`);

        if (response.status === 200) {
            return "Pickup cancelled";
        } else {
            throw response.data;
        }
    } catch (e) {
        console.error(e);
        if (e.response.data.response.errors[0].code === "9510131") {
            throw "Pickup already cancelled";
        } else {
            throw "Pickup not cancelled";
        }
    }
};

const cancelUPSPickup = async (PRN: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/UPS/cancel-pickup/${PRN}`);

        if (response.data.PickupCancelResponse.Response.ResponseStatus.Description === "Success") {
            return "Pickup cancelled";
        } else if (response.data.response.errors[0].code === "9510131") {
            return "Pickup already cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const cancelPurolatorPickup = async (PRN: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/Purolator/cancel-pickup/${PRN}`);
        console.log(response.data);
        if (response.data.PickUpVoided as Boolean) {
            return "Pickup cancelled";
        } else if (response.data.ResponseInformation.Errors && response.data.ResponseInformation.Errors.Error[0].Code === "4100742") {
            return "Pickup already cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const updatePickupStatus = async (id: string | undefined, shipmentID: string | undefined, properties?: object) => {
    if (id === undefined || shipmentID === undefined) {
        throw "ID is undefined";
    }

    await setDoc(doc(db, "pickups", id), { ...properties, shipmentID: arrayRemove(shipmentID) }, { merge: true });
};

const removePickupFromShipment = async (id: string | undefined) => {
    if (id === undefined) {
        throw "ID is undefined";
    }
    try {
        await setDoc(
            doc(db, "shipments", id),
            {
                pickupDetails: {
                    id: null
                }
            } as Shipment,
            { merge: true }
        );
    } catch (e) {
        throw e;
    }
};

const addPickupToShipment = async (pickupID: string | undefined, shipmentID: string | undefined) => {
    if (pickupID === undefined) {
        throw "ID is undefined";
    }
    if (shipmentID === undefined) {
        throw "ID is undefined";
    }
    try {
        await updateDoc(doc(db, "pickups", pickupID), {
            shipmentID: arrayUnion(shipmentID)
        });
        await updateDoc(doc(db, "shipments", shipmentID), {
            pickupDetails: {
                pickupType: "pickup",
                id: pickupID
            }
        });
    } catch (e) {
        console.error(e);
    }
};

/**
 * Updates the statuses of all the user's pickups.
 *
 * @param {string} userEmail - email of the user
 */
const refreshPickupStatuses = async (userEmail: string) => {
    try {
        // Query for user associated pickups
        const pickupsSnapshot = await getDocs(query(collection(db, "pickups"), where("uid", "==", userEmail)));

        pickupsSnapshot.forEach(async (doc) => {
            const carrier = doc.data().carrier;
            if (doc.data().status == "Cancelled") {
                return "Unable to modify pickup; pickup has been cancelled.";
            }
            // Query for associated shipment to retrieve tracking number (NOTE: only checking the first shipment since all associated shipments are assumed to be picked up at the same time)
            const shipmentTrackingNumber = (await getDocs(query(collection(db, "shipments"), where("id", "==", doc.data().shipmentID[0])))).docs[0].data().trackingNumber;
            // Fetch status of associated shipment
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/${carrier}/track-shipment/${shipmentTrackingNumber}`);
            if (carrier.toLowerCase() === "ups") {
                const shipments = response.data.trackResponse.shipment;
                for (const shipment of shipments) {
                    for (const pkg of shipment.package) {
                        // Iterate through the activity of package/shipment and check whether the shipment has been picked up (UPS uses the status code 038 for pickups)
                        const pickupHappened = pkg.activity.some((activity) => activity.status.statusCode === "038");
                        // If the shipment was picked up, then the set the pickup status to Completed
                        if (pickupHappened) updatePickupStatus(doc.data().id, "Completed", doc.data().shipmentID);
                        // If the shipment was not picked up and pickup close time is before current time then the pickup is Delayed
                        else if (dayjs(doc.data().pickupDetails.closeTimeStamp.isoTimeStamp).isBefore(dayjs())) await setDoc(doc.ref, { status: "Delayed" }, { merge: true });
                    }
                }
            } else if (carrier.toLowerCase() == "fedex") {
                const completeTrackResults = response.data.output.completeTrackResults;
                for (const trackResult of completeTrackResults) {
                    for (const result of trackResult.trackResults) {
                        const pickupHappened = result.dateAndTimes.some((dateTime) => dateTime.type === "ACTUAL_PICKUP");
                        if (pickupHappened) updatePickupStatus(doc.data().id, "Completed", doc.data().shipmentID);
                        else if (dayjs(doc.data().pickupDetails.closeTimeStamp.isoTimeStamp).isBefore(dayjs())) await setDoc(doc.ref, { status: "Delayed" }, { merge: true });
                    }
                }
            } else if (carrier.toLowerCase() == "purolator") {
                const scans = response.data.TrackingInformationList.TrackingInformation[0].Scans.Scan;
                for (const scan of scans) {
                    const pickupHappened = scan.Description.includes("Picked up by Purolator");
                    if (pickupHappened) updatePickupStatus(doc.data().id, "Completed", doc.data().shipmentID);
                    else if (dayjs(doc.data().pickupDetails.closeTimeStamp.isoTimeStamp).isBefore(dayjs())) await setDoc(doc.ref, { status: "Delayed" }, { merge: true });
                }
            } // TODO: add gls
        });
    } catch (e) {
        console.error(e);
    }
};

export { cancelPickup, updatePickupStatus, removePickupFromShipment, addPickupToShipment, refreshPickupStatuses };
