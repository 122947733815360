import axios from "axios";
import useRatesStore from "@/core/RatesStore";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useFormStore } from "@/core/ShipmentFormStore";
import { RatesResponse } from "@/types/ResponseTypes";
import { Shipment } from "@shared/ShipmentTypes";
import { useUserStore } from "@/core/UserStore";
import { Partnerships } from "@shared/UserTypes";
import { getPartnershipByID } from "./partnership";
dayjs.extend(relativeTime);

const getRates = async (freeQuotePayload?: any) => {
    let payload: Shipment;
    const carrierFunctions = {
        UPS: UPSRates,
        FedEx: FedExRates,
        Purolator: PurolatorRates,
        GLS: GLSRates
    };
    let promises: Promise<any>[] = [Promise.reject(new Error("Test Error"))];

    useRatesStore.getState().removeRates();

    if (freeQuotePayload) {
        // ? do we still need this??
        payload = {
            shipper: {
                phoneNumber: "+16477707632", // ! for purolator, would be ideal to not do this
                companyName: "N/A",
                attentionName: "N/A",
                email: "N/A",
                address: {
                    postalCode: freeQuotePayload.shipperAddress.postalCode,
                    countryCode: freeQuotePayload.shipperAddress.countryCode,
                    city: freeQuotePayload.shipperAddress.city,
                    stateCode: freeQuotePayload.shipperAddress.stateCode,
                    street: freeQuotePayload.shipperAddress.street
                }
            },
            receiver: [
                {
                    phoneNumber: "+16477707632",
                    companyName: "N/A",
                    attentionName: "N/A",
                    email: "N/A",
                    address: {
                        postalCode: freeQuotePayload.receiverAddress.postalCode,
                        countryCode: freeQuotePayload.receiverAddress.countryCode,
                        city: freeQuotePayload.receiverAddress.city,
                        stateCode: freeQuotePayload.receiverAddress.stateCode,
                        street: freeQuotePayload.receiverAddress.street
                    }
                }
            ],
            shipmentDetails: {
                shipmentType: freeQuotePayload.shipmentDetails.shipmentType,
                units: freeQuotePayload.shipmentDetails.units,
                packages: freeQuotePayload.shipmentDetails.packages,
                signature: "none",
                description: freeQuotePayload.shipmentDetails.description,
                deliveryFormat: freeQuotePayload.shipmentDetails.deliveryFormat,
                printerType: "regular"
            },
            pickupDetails: {
                pickupType: "dropoff"
            },
            customsDetails: {
                exportReason: "SALE",
                products: [
                    {
                        description: "1",
                        quantity: 1,
                        units: "PACKAGE",
                        numberOfPackages: 1,
                        value: 1,
                        weight: 1,
                        country: "CA"
                    }
                ],
                customsPayor: "shipper",
                customsCurrency: "CAD"
            }
        };

        Object.keys(carrierFunctions).forEach((carrier) => {
            promises.push(
                carrierFunctions[carrier](payload).catch((error) => {
                    throw new Error(`Error with ${carrier} request:`, error.response ? error.response.data : error.message);
                })
            );
        });
    } else {
        let carrierNotActive = true;
        const activeCarriers = useUserStore.getState().userData?.activeCarriers;
        const partnershipsID = useUserStore.getState().userData?.partnerships;
        payload = useFormStore.getState().shipment;

        let partnership: Partnerships | undefined;
        if (partnershipsID) {
            partnership = await getPartnershipByID(partnershipsID);
        }

        console.log(partnershipsID, partnership);

        for (const carrier in activeCarriers) {
            if (activeCarriers[carrier].enabled) {
                promises.push(carrierFunctions[carrier](payload, partnership, partnershipsID));
                carrierNotActive = false;
            }
        }

        if (carrierNotActive) {
            throw new Error("carrier not enabled");
        }
    }

    try {
        await Promise.allSettled(promises);
    } catch (e) {
        console.error(e);
    }
};

const FedExSurcharges = {
    FUEL: "fuelSurcharge",
    RESIDENTIAL_DELIVERY: "residentialSurcharge",
    SIGNATURE_OPTION: "signatureSurcharge",
    DELIVERY_AREA: "extendedAreaSurcharge",
    ADDITIONAL_HANDLING: "additionalHandlingSurcharge",
    DEMAND: "peakSeasonSurcharge",
    SATURDAY_PICKUP: "saturdayPickup",
    DECLARED_VALUE: "carrierInsurance",
    INSURED_VALUE: "carrierInsurance"
};

export const FedExRates = async (payload: Shipment, partnerships: Partnerships | undefined) => {
    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/FedEx/rates`,
        data: payload
    })
        .then((Response) => {
            let ratesOutput: Array<object> = Response.data.output.rateReplyDetails;
            ratesOutput.map((rate) => {
                if (rate["serviceType"] == "STANDARD_OVERNIGHT") {
                    return null;
                }
                let date: number = 0;

                if (rate["serviceType"] == "FEDEX_2_DAY") {
                    // overwrite for 2 days
                    date = 2;
                } else if (
                    // overwrite for one day
                    rate["serviceType"] == "SAME_DAY" ||
                    rate["serviceType"] == "FIRST_OVERNIGHT" ||
                    rate["serviceType"] == "PRIORITY_OVERNIGHT" ||
                    rate["serviceType"] == "STANDARD_OVERNIGHT"
                ) {
                    date = 1;
                } else {
                    // manual calc for everything else
                    let transitTimes: Dayjs;
                    if (rate["commit"]["dateDetail"]) {
                        transitTimes = dayjs(rate["commit"]["dateDetail"]["dayFormat"]);
                    } else {
                        transitTimes = dayjs();
                    }

                    if (transitTimes !== dayjs()) {
                        date = transitTimes.diff(dayjs(), "day");
                    } else {
                        date = 1; // ? should this be one?
                    }
                }

                const shipmentDetails: Array<any> = rate["ratedShipmentDetails"];

                const listedRate = shipmentDetails.find((rate) => {
                    return rate["rateType"] === "LIST"; // GENERAL RATE
                });

                const discountedRate = shipmentDetails.find((rate) => {
                    return rate["rateType"] === "ACCOUNT"; // OUR RATES
                });

                if (!discountedRate || (listedRate && listedRate["totalNetCharge"] === discountedRate["totalNetCharge"])) {
                    // if we don't have a discount
                    // look at FedEx FIRST_OVERNIGHT for example
                    return null;
                }

                // we have 60% discount with FedEx
                const fedexDiscount = 0.6;
                const originalCost = listedRate ? Number(listedRate["totalNetCharge"]) : Number(discountedRate["totalNetCharge"] / (1 - fedexDiscount)); // published total cost

                // we will charge 5%, 2% margin + 3% to cover administrative costs (i.e stripe)
                const margin: number = partnerships?.FedEx?.all ?? partnerships?.all ?? 0.05;

                let additionalCharges = 0;
                let totalTaxAmount = 0;
                let totalSurchageAmount = 0;
                const costBreakdown: RatesResponse["costBreakdown"] = [];

                (discountedRate["shipmentRateDetail"]["taxes"] as Array<object>)?.forEach((tax) => {
                    const taxAmount = tax["amount"];
                    totalTaxAmount += taxAmount;
                });

                (discountedRate["shipmentRateDetail"]["surCharges"] as Array<object>).forEach((surcharge) => {
                    let surchargeType = FedExSurcharges[surcharge["type"]];

                    let surchargeCost = Number(surcharge["amount"]);

                    if (surchargeType !== undefined) {
                        costBreakdown.push({ [surchargeType]: surchargeCost });
                        totalSurchageAmount += surchargeCost;
                    } else {
                        additionalCharges += surchargeCost;
                    }
                });

                // discountedRate["totalBaseCharge"] isn't this (not clear what it is)
                const baseCharge: number = Number(discountedRate["totalNetCharge"]) - totalSurchageAmount - totalTaxAmount;

                if (additionalCharges !== 0) {
                    costBreakdown.push({ additionalCharges });
                }

                // unclear which to choose for the actual final total, just pick the larger one
                const totalNetChargeWithDutiesAndTaxes = discountedRate["totalNetChargeWithDutiesAndTaxes"] ?? 0;
                const totalNetCharge = discountedRate["totalNetCharge"];
                const netCharge = totalNetChargeWithDutiesAndTaxes > totalNetCharge ? totalNetChargeWithDutiesAndTaxes : totalNetCharge;

                const marginValue = netCharge * margin;
                const cost = netCharge + marginValue;

                costBreakdown.push({ administrativeFee: marginValue });
                costBreakdown.unshift({ baseCharge: baseCharge + totalTaxAmount }); // base charge on top

                // * admin fee
                // const adminFee = cost * globalMargin;
                // cost += adminFee;
                // costBreakdown.push({ administrativeFee: adminFee });

                const rateResponse: RatesResponse = {
                    originalCost: originalCost,
                    cost: cost,
                    costBreakdown: costBreakdown,
                    carrier: "FedEx",
                    transitTimes: date,
                    ServiceName: rate["serviceName"],
                    ServiceCode: rate["serviceType"]
                };
                useRatesStore.getState().addRates(rateResponse);
            });
        })
        .catch((error) => {
            console.log(error);
        });
};

export const UPSRates = async (payload: Shipment, partnerships: Partnerships | undefined) => {
    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/UPS/rates`,
        data: payload
    })
        .then((Response) => {
            console.log(Response);
            let ratesOutput = Response.data.RateResponse.RatedShipment;

            // console.log(typeof ratesOutput);

            if (!ratesOutput.length) {
                getUPSPricing(ratesOutput, payload, partnerships);
            } else {
                // * Multiple Rates
                ratesOutput.map((rate: Object) => {
                    getUPSPricing(rate, payload, partnerships);
                });
            }
        })

        .catch((error) => {
            console.log(error);
        });
};

// * mapping surcharge codes to name
const UPSSurchargeCodes = {
    "120": "signatureSurcharge",
    "375": "fuelSurcharge",
    "270": "residentialSurcharge",
    "434": "surgeFee",
    "190": "extendedAreaSurcharge",
    "100": "additionalHandlingSurcharge",
    "430": "peakSeasonSurcharge",
    "400": "carrierInsurance"
};

/* 
    The UPS endpoint returns both the published rate as well as our discounted rates. The only issue that seems to be occuring is that UPS doesn't add a tax for the discounted rates (or the published rate)
    
    We have to add a tax by ourselves depending on where they are shipping to if I'm correct about that.
    This does mean we can use a profit margin instead
*/

const getUPSPricing = async (rate: Object, shipment: Shipment, partnerships: Partnerships | undefined) => {
    if (rate["TimeInTransit"]?.["ServiceSummary"]["Service"]["Description"] === "UPS Express Early") {
        return null;
    }

    const discountedRate = rate["NegotiatedRateCharges"];

    if (!discountedRate) {
        return null;
    }

    let additionalCharges = 0;

    const costBreakdown: RatesResponse["costBreakdown"] = [];

    //https://developer.ups.com/api/reference/shipping/appendix1?loc=en_US
    if (discountedRate["ItemizedCharges"]) {
        for (const surcharge of discountedRate["ItemizedCharges"] as Array<object>) {
            if (UPSSurchargeCodes[surcharge["Code"]]) {
                const value = Number(surcharge["MonetaryValue"]);
                const name = UPSSurchargeCodes[surcharge["Code"]];
                costBreakdown.push({ [name]: value });
            } else {
                const value = Number(surcharge["MonetaryValue"]);
                additionalCharges += value;
            }
        }
    } else if (rate["ItemizedCharges"]) {
        // needed for development environment
        for (const surcharge of rate["ItemizedCharges"] as Array<object>) {
            if (UPSSurchargeCodes[surcharge["Code"]]) {
                const value = Number(surcharge["MonetaryValue"]);
                const name = UPSSurchargeCodes[surcharge["Code"]];
                costBreakdown.push({ [name]: value });
            } else {
                const value = Number(surcharge["MonetaryValue"]);
                additionalCharges += value;
            }
        }
    }

    if (additionalCharges !== 0) {
        costBreakdown.push({ additionalCharges });
    }

    // signature surcharge per package
    if ("ItemizedCharges" in (rate["RatedPackage"][0] as object)) {
        for (const surcharge of rate["RatedPackage"][0]["ItemizedCharges"] as Array<Object>) {
            if (surcharge["Code"] === "120") {
                const value = Number(surcharge["MonetaryValue"]) === 8.2 ? 5.1 : Number(surcharge["MonetaryValue"]);
                const name = UPSSurchargeCodes[surcharge["Code"]];
                costBreakdown.push({ [name]: value });
            }
        }
    }

    // UPS doesn't return tax sometimes even with the tax indicator set in their API for some reason
    let taxRate = 0;
    if (shipment.receiver[0].address.countryCode === "CA") {
        if (
            shipment.receiver[0].address.stateCode === "NS" ||
            shipment.receiver[0].address.stateCode === "PE" ||
            shipment.receiver[0].address.stateCode === "NL" ||
            shipment.receiver[0].address.stateCode === "NB"
        ) {
            taxRate = 0.15;
        } else if (
            shipment.receiver[0].address.stateCode === "AB" ||
            shipment.receiver[0].address.stateCode === "BC" ||
            shipment.receiver[0].address.stateCode === "MB" ||
            shipment.receiver[0].address.stateCode === "NT" ||
            shipment.receiver[0].address.stateCode === "QC" ||
            shipment.receiver[0].address.stateCode === "SK" ||
            shipment.receiver[0].address.stateCode === "YT"
        ) {
            taxRate = 0.05;
        } else if (shipment.receiver[0].address.stateCode === "ON") {
            taxRate = 0.13;
        }
    }

    const margin = partnerships?.UPS?.all ?? partnerships?.all ?? 0.05;
    const originalCostWithoutTax = Number(rate["TotalCharges"]["MonetaryValue"]); // published rate
    const originalCostTax = originalCostWithoutTax * taxRate;
    const originalCost = originalCostWithoutTax + originalCostTax;

    const discountedCostWithoutTax = Number(discountedRate["TotalCharge"]["MonetaryValue"]);
    const discountedCostTax = discountedCostWithoutTax * taxRate;
    const discountedCost = discountedCostWithoutTax + discountedCostTax;

    const baseCharge = Number(discountedRate["BaseServiceCharge"]["MonetaryValue"]);

    const marginValue = discountedCost * margin;
    const cost = discountedCost + marginValue;

    costBreakdown.push({ administrativeFee: marginValue });
    costBreakdown.unshift({ baseCharge: baseCharge + discountedCostTax }); // base charge on top

    const rateResponse: RatesResponse = {
        originalCost,
        cost,
        costBreakdown,
        carrier: "UPS",
        transitTimes: Number(rate["TimeInTransit"]["ServiceSummary"]["EstimatedArrival"]["BusinessDaysInTransit"]),
        ServiceName: rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"],
        ServiceCode: rate["Service"]["Code"]
    };
    useRatesStore.getState().addRates(rateResponse);
};

const PurolatorSurcharges = {
    Fuel: "fuelSurcharge",
    ResidentialDelivery: "residentialSurcharge"
};

const PurolatorOptions = {
    SaturdayPickup: "saturdayPickup",
    DeclaredValue: "carrierInsurance"
};
export const PurolatorRates = async (payload: Shipment, partnerships: Partnerships | undefined, partnershipsID: string | undefined) => {
    return await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/Purolator/rates`,
        data: payload
    }).then((Response) => {
        let ratesOutput: Array<object> = Response.data.ShipmentEstimates.ShipmentEstimate;
        ratesOutput.forEach((rate) => {
            let purolatorDiscount: number; // * discount that is applied by Purolator, used to get original cost
            let baseMargin = 0.05;
            if (partnershipsID === "purolator_inverse") {
                baseMargin = 0.1;
            }
            let margin: number;

            const serviceCode = rate["ServiceID"];

            if (serviceCode === "PUROLATOR GROUND") {
                purolatorDiscount = 0.65; // 65% off
                margin = partnerships?.Purolator?.ground ?? partnerships?.Purolator?.all ?? partnerships?.all ?? baseMargin;
            } else {
                purolatorDiscount = 0.6; // 60% off
                margin = partnerships?.Purolator?.all ?? partnerships?.all ?? baseMargin;
            }

            const discountCost = rate["TotalPrice"];
            const originalCost = discountCost / (1 - purolatorDiscount); // * published rate
            const baseCharge = rate["BasePrice"];
            let totalTaxAmount = 0;
            let costBreakdown: RatesResponse["costBreakdown"] = [];

            let additionalCharges = 0; // * edge case for if surcharge isn't mapped

            rate["Taxes"] &&
                (rate["Taxes"]["Tax"] as Array<object>).forEach((tax) => {
                    const taxAmount = tax["Amount"];
                    totalTaxAmount += taxAmount;
                });

            (rate["Surcharges"]["Surcharge"] as Array<object>).forEach((surcharge) => {
                let surchargeType = PurolatorSurcharges[surcharge["Type"]];

                let surchargeCost = surcharge["Amount"];

                if (surchargeType !== undefined) {
                    costBreakdown.push({ [surchargeType]: surchargeCost });
                } else {
                    additionalCharges += surchargeCost;
                }
            });

            rate["OptionPrices"] &&
                (rate["OptionPrices"]["OptionPrice"] as Array<object>).forEach((option) => {
                    let optionType = PurolatorOptions[option["ID"]];

                    let optionCost = option["Amount"];

                    if (optionType !== undefined) {
                        costBreakdown.push({ [optionType]: optionCost });
                    } else {
                        additionalCharges += optionCost;
                    }
                });

            additionalCharges > 0 && costBreakdown.push({ additionalCharges });

            const marginValue = discountCost * margin;
            const cost = discountCost + marginValue;

            costBreakdown.push({ administrativeFee: marginValue });
            costBreakdown.unshift({ baseCharge: baseCharge + totalTaxAmount }); // base charge on top

            const rateResponse: RatesResponse = {
                originalCost,
                cost,
                costBreakdown,
                carrier: "Purolator",
                transitTimes: rate["EstimatedTransitDays"],
                ServiceName: serviceCode
                    .replace(/([A-Z])/g, " $1")
                    .replace("Express", "Express ")
                    .replace("Envelope", "Envelope ")
                    .replace("Pack", "Pack ")
                    .replace("Box", "Box ")
                    .replace("U. S.", "U.S. ")
                    .replace(" A M", "AM")
                    .replace(" P M", "PM")
                    .trim(),
                ServiceCode: serviceCode
            };

            useRatesStore.getState().addRates(rateResponse);
        });
    });
};

const GLSSurchargeCodes = {
    PHD: "residentialSurcharge",
    CHN: "carbonSurcharge",
    PKS: "peakSeasonSurcharge",
    DCV: "carrierInsurance"
};

export const GLSRates = async (payload: Shipment, partnerships: Partnerships | undefined) => {
    if (!(payload.shipper.address.countryCode === "CA" && payload.receiver[0].address.countryCode === "CA")) {
        return;
    }
    let transitTimes: number = 0;

    try {
        const requestBody = {
            shipperPostalCode: payload.shipper.address.postalCode,
            receiverPostalCode: payload.receiver[0].address.postalCode
        };
        const transitTimesResponse = await axios({
            method: "POST",
            url: `${import.meta.env.VITE_API_URL}/GLS/transit-times`,
            data: requestBody
        });

        transitTimes = transitTimesResponse.data.delay;
    } catch (error) {
        console.error(error);
        transitTimes = 0;
    }

    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/GLS/rates`,
        data: payload
    })
        .then((Response) => {
            console.log(Response);
            let ratesOutput: Array<any> = Response.data.rates;

            const margin = partnerships?.GLS?.all ?? partnerships?.all ?? 0.05;

            const generalRate = ratesOutput.find((rate) => {
                return rate["accountType"] === "GEN"; // published general rate
            });

            const discountedRate = ratesOutput.find((rate) => {
                return rate["accountType"] === "NEG"; // our negotiated rate
            });

            const totalTaxAmount = discountedRate["taxes"];
            const baseCharge = discountedRate["basicCharge"] + discountedRate["weightCharge"];
            const fuelSurcharge = discountedRate["fuelCharge"];

            const costBreakdown: RatesResponse["costBreakdown"] = [
                {
                    fuelSurcharge: fuelSurcharge
                }
            ];

            let additionalCharges = 0;

            (discountedRate["surcharges"] as Array<any>).forEach((surcharge) => {
                if (GLSSurchargeCodes[surcharge["type"]] !== undefined) {
                    costBreakdown.push({ [GLSSurchargeCodes[surcharge["type"]]]: surcharge["amount"] });
                } else if (surcharge["name"] !== undefined) {
                    costBreakdown.push({ [surcharge["name"]]: surcharge["amount"] });
                } else {
                    additionalCharges += surcharge["amount"];
                }
            });

            additionalCharges !== 0 && costBreakdown.push({ additionalCharges });

            const discountedTotal = discountedRate["total"];
            const marginValue = discountedTotal * margin;
            const cost = discountedTotal + marginValue;

            costBreakdown.push({ administrativeFee: marginValue });
            costBreakdown.unshift({ baseCharge: baseCharge + totalTaxAmount }); // base charge on top

            const rateResponse: RatesResponse = {
                cost,
                originalCost: generalRate["total"],
                costBreakdown,
                carrier: "GLS",
                ServiceCode: "GLS",
                ServiceName: "GLS",
                transitTimes: transitTimes ?? 0 // should change to 1 or 2
            };

            useRatesStore.getState().addRates(rateResponse);
        })

        .catch((error) => {
            console.log(error);
        });
};

export default getRates;
