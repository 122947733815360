import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import Form from "@/components/composite/Form";
import { auth } from "@/core/firebase";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "@/schema/UserSchema";
import Input from "@/components/composite/Inputs/Input";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { Label } from "@/components/ui/label";
import Trucks from "@/assets/misc/Trucks.svg";
import { useAddressBookStore } from "@/core/AddressBookStore";
import { getAddresses } from "@/api/addresses";
import { useUserStore } from "@/core/UserStore";

export default function LoginForm({
    login = async (email: string, password: string) => {
        await signInWithEmailAndPassword(auth, email, password);
    }
}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof loginSchema>>({
        resolver: zodResolver(loginSchema),
        mode: "all",
        reValidateMode: "onChange"
    });

    const onSubmit = async (data: z.infer<typeof loginSchema>) => {
        setIsLoading(true);
        try {
            await login(data.email, data.password);
            const email = useUserStore.getState().user?.email;
            const addressBook = await getAddresses(email);
            if (addressBook) {
                useAddressBookStore.getState().addAddressBook(addressBook);
            }
            navigate("/", { replace: true });
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            let message = "";

            if (errorCode === "auth/wrong-password") {
                message = "Invalid password";
                methods.setError("password", { message });
            } else if (errorCode === "auth/invalid-credential") {
                message = "Invalid credential";
                methods.setError("root", { message });
            } else if (errorCode === "auth/user-not-found") {
                message = "User not found";
                methods.setError("email", { message });
            } else {
                message = `${errorCode} ${errorMessage}`;
                methods.setError("root", { message });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
            <div className="relative flex items-center justify-center bg-white py-12">
                <div className="absolute left-0 right-0 top-0 flex justify-center p-9 lg:justify-between">
                    <img src={logo} className="h-10 place-self-center object-cover" />
                </div>
                <div className="mx-auto mt-20 grid w-[350px] gap-6 lg:my-auto">
                    <div className="grid gap-2 text-center">
                        <h1 className="text-3xl font-bold">Login</h1>
                        <p className="text-muted-foreground text-balance">Enter your email and password below to login</p>
                    </div>
                    <Form methods={methods} onSubmit={onSubmit} className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">Email</Label>
                            <Input name="email" type="email" placeholder="Enter email" />
                        </div>
                        <div className="grid gap-2">
                            <div className="flex items-center">
                                <Label htmlFor="password">Password</Label>
                                <a href="/reset-password" className="ml-auto text-sm underline-offset-4 hover:underline">
                                    Forgot your password?
                                </a>
                            </div>
                            <Input name="password" type="password" placeholder="Enter password" />
                        </div>
                        <LoadingButton isLoading={isLoading} className="w-full" type="submit">
                            Login
                        </LoadingButton>
                    </Form>
                    <div className="mt-4 text-center text-sm">
                        Don&apos;t have an account?{" "}
                        <Link to="/sign-up" className="underline">
                            Sign up
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block" style={{ backgroundColor: "#FCFCFC" }}>
                <img src={Trucks} alt="" className="max-h-screen w-full" />
            </div>
        </div>
    );
}
