import { Button } from "@/components/ui/button";
import { CreditCard } from "lucide-react";
import { useFormStore } from "@/core/ShipmentFormStore";
import { useState, useEffect } from "react";
import { useStepper } from "@/components/ui/stepper";
import { useNavigate } from "react-router-dom";
import OrderDetails from "@/components/composite/OrderDetails";
import { createInvoiceItem, createPayment } from "@/api/payments/createPayment";
import useRatesStore from "@/core/RatesStore";
import { useUserStore } from "@/core/UserStore";
import { generateID } from "@/api/generateID";
import dayjs from "dayjs";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { addAddress, editAddress } from "@/api/addresses";
import { useSaveAddressStore } from "@/core/SaveAddress";
import { validateShipment } from "@/api/shipments/validateShipment";
import { useToast } from "@/components/ui/use-toast";
import { useAddressBookStore } from "@/core/AddressBookStore";

export default function ConfirmShipment() {
    // const [cost, setCost] = useState<number | undefined>();
    const cost = useFormStore((state) => state.shipment.cost);
    const [id, setID] = useState("");
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [loadingInvoice, setLoadingInvoice] = useState(false);
    const [validateLoading, setValidateLoading] = useState(true);
    const [validateShipmentResponse, setValidateShipmentResponse] = useState<Record<string, any>>({});
    const userData = useUserStore((state) => state.userData);
    const editData = useFormStore((state) => state.editData);
    const shipmentDetails = useFormStore((state) => state.shipment);
    const addressBook = useAddressBookStore((state) => state.addressBook);
    const { toast } = useToast();

    useEffect(() => {
        generateID()
            .then((response: string) => {
                setID(response);
                editData({ id: response, date: dayjs().format() });
            })
            .catch((error) => {
                console.error(error);
            });

        validateShipment(shipmentDetails).then((result) => {
            setValidateLoading(false);
            setValidateShipmentResponse(result);

            if (result?.status === "error" || result?.status === "warning") {
                toast({
                    title: "Shipment Error",
                    description: (
                        <>
                            {result?.messages.map((message: string, index: number) => (
                                <>
                                    {message}
                                    {index !== result.messages.length - 1 && <br />}
                                </>
                            ))}
                        </>
                    ),
                    variant: "destructive"
                });
            }
        });
    }, []);

    const { prevStep, nextStep } = useStepper();

    const service = useFormStore((state) => state.serviceDetails.serviceName);

    const navigate = useNavigate();

    const handleCost = async () => {
        setLoadingPayment(true);
        try {
            if (!userData || !cost) throw new Error("User data or cost is missing.");

            editData({
                uid: userData.email
            });

            const response = await createPayment(cost, service, userData.stripe);
            let url = response?.data?.url;

            if (!url) throw new Error("Failed to fetch payment URL.");

            useSaveAddressStore.getState().setShipperIndex(undefined);
            useSaveAddressStore.getState().setReceiverIndex(undefined);

            if (useSaveAddressStore.getState().saveShipper) {
                addAddress(shipmentDetails.shipper, userData.email);
            }
            if (useSaveAddressStore.getState().saveReceiver) {
                shipmentDetails.receiver.forEach((address) => {
                    addAddress(address, userData.email);
                });
            }

            if (useSaveAddressStore.getState().shipperDefault && addressBook) {
                editAddress(shipmentDetails.shipper, { ...shipmentDetails.shipper, defaultShipper: true }, userData.email)

                for (const address of addressBook) {
                    if (address.defaultShipper) {
                        const { defaultShipper, ...rest } = shipmentDetails.shipper
                        editAddress(shipmentDetails.shipper, rest, userData.email)
                    }
                }
            }
            if (useSaveAddressStore.getState().receiverDefault && addressBook) {
                shipmentDetails.receiver.forEach((address) => {
                    // this never has more than one so multiple default receivers isn't a problem
                    editAddress(address, { ...address, defaultReceiver: true }, userData.email)

                    for (const address of addressBook) {
                        if (address.defaultReceiver) {
                            const { defaultReceiver, ...rest } = address
                            editAddress(address, rest, userData.email)
                        }
                    }
                });
            }

            useSaveAddressStore.getState().setShipperDefault(false);
            useSaveAddressStore.getState().setReceiverDefault(false);
            useRatesStore.getState().removeRates();
            window.location.replace(url);
        } catch (e) {
            toast({
                title: "Sorry, something went wrong with your shipment.",
                description: `${e.message} Please try again.`,
                variant: "destructive"
            });
        } finally {
            setLoadingPayment(false);
        }
    };

    const handleInvoice = async () => {
        setValidateLoading(true);
        setLoadingInvoice(true);
        try {
            if (!userData || !cost) throw new Error("User data or cost is missing.");

            editData({
                uid: userData.email
            });

            const status = await createInvoiceItem(cost, service, userData.stripe, userData.invoiceCycle, userData.dueDate);

            if (!status.data) throw new Error("Failed to create invoice.");

            useSaveAddressStore.getState().setShipperIndex(undefined);
            useSaveAddressStore.getState().setReceiverIndex(undefined);

            if (useSaveAddressStore.getState().saveShipper) {
                addAddress(shipmentDetails.shipper, userData.email);
            }
            if (useSaveAddressStore.getState().saveReceiver) {
                shipmentDetails.receiver.forEach((address) => {
                    addAddress(address, userData.email);
                });
            }

            if (useSaveAddressStore.getState().shipperDefault && addressBook) {
                for (const address of addressBook) {
                    if (address.defaultShipper) {
                        const { defaultShipper, ...rest } = address
                        editAddress(address, rest, userData.email)
                    }
                }

                editAddress(shipmentDetails.shipper, { ...shipmentDetails.shipper, defaultShipper: true }, userData.email)
            }
            if (useSaveAddressStore.getState().receiverDefault && addressBook) {
                shipmentDetails.receiver.forEach((address) => {
                    // this never has more than one so multiple default receivers isn't a problem
                    editAddress(address, { ...address, defaultReceiver: true }, userData.email)

                    for (const _address of addressBook) {
                        if (_address.defaultReceiver) {
                            const { defaultReceiver, ...rest } = _address
                            editAddress(_address, rest, userData.email)
                        }
                    }
                });
            }

            useSaveAddressStore.getState().setShipperDefault(false);
            useSaveAddressStore.getState().setReceiverDefault(false);
            useRatesStore.getState().removeRates();

            navigate(`/shipment-success/${id}`);
        } catch (e) {
            toast({
                title: "Sorry, something went wrong with your shipment.",
                description: `${e.message} Please try again.`,
                variant: "destructive"
            });
        } finally {
            setLoadingInvoice(false);
        }
    };

    // ? TODO: I changed the status to be when error instead of not success
    return (
        <>
            <OrderDetails type="confirm" shipment={shipmentDetails} />
            <div className="flex w-full gap-6">
                <Button type="button" onClick={prevStep} variant="outline" className="flex-grow" disabled={loadingPayment || loadingInvoice}>
                    Back
                </Button>
                <LoadingButton
                    type="submit"
                    className="flex-grow"
                    onClick={handleCost}
                    isLoading={loadingPayment}
                    disabled={id === "" || validateLoading || validateShipmentResponse?.status === "error" || loadingInvoice}>
                    Pay now
                </LoadingButton>
                {userData?.invoiceCycle && (
                    <LoadingButton
                        type="button"
                        onClick={handleInvoice}
                        isLoading={loadingInvoice}
                        disabled={id === "" || validateLoading || validateShipmentResponse?.status === "error" || loadingPayment}>
                        <CreditCard className="mr-2" />
                        Invoice Me
                    </LoadingButton>
                )}
            </div>
        </>
    );
}
