import Input from "@/components/composite/Inputs/Input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Select from "@/components/composite/Inputs/Select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Info } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { PackageDimensions } from "@shared/ShipmentTypes";

const SelectItemList = [
    {
        name: "regular",
        label: "Regular"
    },
    {
        name: "thermal",
        label: "Thermal"
    }
];

export default function ShipmentInformation() {
    const { getValues, watch } = useFormContext();

    let [defaultDescription, setDefaultDescription] = useState(getValues("description") ?? "");

    useEffect(() => {
        const { unsubscribe } = watch((value) => {
            const packages: PackageDimensions[] = value.packages;
            const currentDescription = value.description;

            if (!currentDescription) {
                if (packages) {
                    const descriptionArray = new Set([
                        ...packages.map((box) => {
                            return box.description as string;
                        })
                    ]);

                    setDefaultDescription([...descriptionArray].join(", ").replace(/,\s*$/, ""));
                }
            }
        });

        return () => unsubscribe();
    }, [watch]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDefaultDescription(value);
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center text-base font-medium">
                    Shipment Details
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger type="button">
                                <Info className="ml-2 h-4" />
                            </TooltipTrigger>
                            <TooltipContent className=" text-left">
                                <p>Please be specific in your descriptions. For example, if you are sending:</p>
                                <ul>
                                    <li>Accounting documents, label them as 'accounting documents' rather than simply 'paper.'</li>
                                    <li>A t-shirt, specify 'women's cotton t-shirt' instead of just 't-shirt.'</li>
                                </ul>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </CardTitle>
            </CardHeader>
            <CardContent className="grid grid-cols-[3fr_1fr] gap-4">
                <div className="grid grid-flow-col items-center gap-2">
                    <Input name="description" data-testid="description" role="description" label="Description of Shipment" value={defaultDescription} onChange={handleChange} />
                </div>
                <div className="grid grid-flow-col gap-2">
                    <Select SelectItemList={SelectItemList} name="printerType" defaultValue={getValues("printerType") ?? "regular"} label="Printer Type for Label" />
                </div>
                {/* <Checkbox name="returnDetails.isReturnShipment" label="This is a return shipment" /> */}
                {/* <Input name="returnDetails" label="If this is a return shipment, please put a description here" /> */}
            </CardContent>
        </Card>
    );
}
