import { create } from "zustand";

interface UseSaveAddressProps {
    saveShipper: boolean;
    saveReceiver: boolean;
    shipperDefault: boolean;
    receiverDefault: boolean;
    shipperIndex: number | undefined;
    receiverIndex: number | undefined;
    setSaveShipper: (value: boolean | undefined) => void;
    setSaveReceiver: (value: boolean | undefined) => void;
    setShipperDefault: (value: boolean | undefined) => void;
    setReceiverDefault: (value: boolean | undefined) => void;
    setShipperIndex: (value: number | undefined) => void;
    setReceiverIndex: (value: number | undefined) => void;
}

export const useSaveAddressStore = create<UseSaveAddressProps>((set, get) => ({
    saveShipper: false,
    saveReceiver: false,
    shipperDefault: false,
    receiverDefault: false,
    shipperIndex: undefined,
    receiverIndex: undefined,
    setSaveShipper: (value) => {
        set({ saveShipper: value });
    },
    setSaveReceiver: (value) => {
        set({ saveReceiver: value });
    },
    setShipperDefault: (value) => {
        set({ shipperDefault: value });
    },
    setReceiverDefault: (value) => {
        set({ receiverDefault: value });
    },
    setShipperIndex: (value) => {
        set({ shipperIndex: value });
    },
    setReceiverIndex: (value) => {
        set({ receiverIndex: value });
    }
}));
