import { Button } from "@/components/ui/button";
import { CreditCard, Truck, Ban, ClipboardPen, Battery, Undo2 } from "lucide-react";
import DetailSection from "@/components/composite/OrderDetails/DetailSection";
import ShippingAddress from "@/components/composite/OrderDetails/ShippingAddress";
import CarrierLogo from "@/components/composite/CarrierLogo";
import { voidShipment, trackShipment } from "@/api/shipments/updateShipment";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@/components/ui/alert-dialog";
import { Shipment } from "@shared/ShipmentTypes";
import { useState, useEffect } from "react";
import { toast } from "../ui/use-toast";
import * as StripeAPI from "@/api/payments/getPaymentInfo";
import dayjs from "dayjs";
import PackageDetailsSection from "./OrderDetails/PackageDetailsSection";
import { statusStyleMapping, statusIconMapping } from "@/lib/statusMapping";
import { calculateCost } from "@/lib/calculateCost";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import { capitalizeFirstLetter, titleCase } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Table, TableBody, TableCell, TableRow } from "../ui/table";

interface OrderDetailProps {
    type: "confirm" | "complete" | "details";
    shipment: Shipment;
}

export default function OrderDetails({ type, shipment }: OrderDetailProps) {
    const [charge, setCharge] = useState<any>(null);
    const [paymentInfo, setPaymentInfo] = useState<any>(null);
    const [resolved, setResolved] = useState(shipment.status != "Label Created");

    const fetchPaymentMethod = async () => {
        try {
            if (shipment.paymentSessionID?.startsWith("pi")) {
                const paymentIntent = await StripeAPI.getPaymentIntent(shipment.paymentSessionID);
                const charge = await StripeAPI.getChargeObject(paymentIntent.latest_charge);

                setCharge(charge);
            } else {
                throw "This is an invoice item";
            }
        } catch (error) {
            console.error(error);
        }
    };

    const { tax, total, taxPercentage } = calculateCost(Number(shipment.cost), shipment.shipper.address.countryCode === "CA" && shipment.receiver[0].address.countryCode === "CA", shipment);

    const costBreakdown = [
        { label: "Subtotal", value: shipment.cost && "$" + shipment.cost.toFixed(2) },
        // { label: "Adminstration Fees", value: admin && "$" + admin },
        // { label: "Tax", value: tax && "$" + tax },
        { label: "Total", value: total && "$" + total.toFixed(2) }
    ];

    {
        type !== "confirm" &&
            useEffect(() => {
                if (!paymentInfo) {
                    fetchPaymentMethod();
                }
            }, [shipment.paymentSessionID]);
    }

    const handleVoidShipment = async () => {
        try {
            // Check if the shipment has a pickup scheduled; if it does, cancel it
            if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id !== null) {
                const pickupData = await getPickupByShipmentID(shipment.id);
                await cancelPickup(pickupData);
                await updatePickupStatus(pickupData.id, shipment.id, { status: "Cancelled" });
                await removePickupFromShipment(shipment.id);
            }
            await voidShipment(shipment.serviceDetails?.carrier, shipment.trackingNumber, shipment.id);
            await createRefund(shipment.paymentSessionID);
            setResolved(true); // Disables the void button and downloading/printing the label
            toast({ className: "bg-success-400", title: "Shipment voided successfully." });
        } catch (e) {
            toast({ variant: "destructive", title: "Failed to void shipment.", description: "Error: " + e.message });
        }
    };

    return (
        <div className="border-gray w-full rounded-lg border-2 border-solid" data-testid="order-details">
            <div className="flex w-full items-center justify-between rounded-t-lg bg-gray-100 p-4">
                <div>
                    <h1 className="text-lg font-semibold">{shipment.id}</h1>
                    <p className="text-sm text-gray-500">
                        Date {type == "details" && "ordered"}: {dayjs(shipment.date).format("MM/DD/YYYY")}
                    </p>
                    {type == "details" && shipment.status && (
                        <p className={"flex items-center " + statusStyleMapping[shipment.status]}>
                            {statusIconMapping[shipment.status]}
                            {shipment.status}
                        </p>
                    )}
                </div>
                <div className="flex space-x-4">
                    {type == "details" && (
                        <>
                            <Button onClick={() => trackShipment(shipment.serviceDetails?.carrier, shipment.trackingNumber)}>
                                <Truck className="mr-2" />
                                Track
                            </Button>
                            <AlertDialog>
                                <AlertDialogTrigger disabled={resolved}>
                                    <Button disabled={resolved} variant="destructive">
                                        <Ban className="mr-2" />
                                        Void
                                    </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently void your shipment.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={handleVoidShipment}>Continue</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </>
                    )}
                    {/* {type == "confirm" && handleInvoice && (
                        <LoadingButton
                            type="button"
                            onClick={async () => {
                                setWaitingInvoice(true);
                                await handleInvoice();
                                setWaitingInvoice(false);
                            }}
                            isLoading={WaitingInvoice}>
                            <CreditCard className="mr-2" />
                            Invoice Me
                        </LoadingButton>
                        // <Button onClick={async (event) => {

                        //     handleInvoice(event)
                        //     }} disabled={total === undefined}>
                        //     <CreditCard className="mr-2" />
                        //     Invoice Me
                        // </Button>
                    )} */}
                </div>
            </div>

            <div className="p-5">
                <DetailSection
                    title="Order Details"
                    details={[
                        {
                            label: (
                                <>
                                    <CarrierLogo carrier={shipment.serviceDetails?.carrier} className="mr-2 h-4" />
                                    {shipment.serviceDetails?.serviceName}
                                </>
                            ),
                            value: (
                                <TooltipProvider delayDuration={300}>
                                    <Tooltip>
                                        <TooltipTrigger className={shipment.costBreakdown ? "underline decoration-dotted" : ""}>{shipment.cost && "$" + shipment.cost.toFixed(2)}</TooltipTrigger>
                                        <TooltipContent side="left">
                                            {shipment.costBreakdown && shipment.costBreakdown !== "Flat Rate" ? (
                                                <Table>
                                                    <TableBody>
                                                        {shipment.costBreakdown.flatMap(Object.keys).map((cost, index) => {
                                                            // * breaks down the key and makes it into a readable string
                                                            const surchargeName = cost
                                                                .split(/(?=[A-Z])/)
                                                                .map((str) => titleCase(str))
                                                                .join(" ");

                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>{surchargeName}</TableCell>
                                                                    <TableCell>{shipment.costBreakdown && shipment.costBreakdown[index][cost].toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            ) : (
                                                <p className="p-4">Flat Rate Shipping Applied</p>
                                            )}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            )
                        }
                    ]}
                    data-testid="cost"
                />
                <hr className="my-4" />
                <DetailSection
                    title=""
                    details={
                        tax !== 0
                            ? [...costBreakdown.splice(0, 1), { label: `Tax (${(taxPercentage * 100).toFixed(0)}%)`, value: tax && "$" + tax.toFixed(2) }, ...costBreakdown.splice(0)]
                            : costBreakdown
                    }
                />
                <hr className="my-4" />
                <div className="flex flex-wrap">
                    <div className="w-1/2">
                        <ShippingAddress title="Shipping From" customer={shipment.shipper} />
                    </div>
                    <div className="flex w-1/2 flex-col space-y-5">
                        <ShippingAddress title="Shipping To" customer={shipment.receiver[0]} />
                    </div>
                </div>
                <hr className="my-4" />
                {shipment.shipmentDetails.shipmentType == "Package" ? (
                    <PackageDetailsSection
                        title="Package Information"
                        description={shipment.shipmentDetails.description}
                        packages={shipment.shipmentDetails.packages}
                        units={shipment.shipmentDetails.units}
                    />
                ) : (
                    <DetailSection
                        title="Package Information"
                        details={[
                            { label: "Type", value: shipment.shipmentDetails.shipmentType },
                            {
                                label: "Weight",
                                value: (
                                    <>
                                        {shipment.shipmentDetails.packages[0].weight} {shipment.shipmentDetails.units == "imperial" ? "lb" : "kg"}
                                    </>
                                )
                            },
                            { label: "Description", value: shipment.shipmentDetails.description }
                        ]}
                    />
                )}
                {shipment.shipmentDetails.packages[0].batteries || shipment.shipmentDetails.signature !== "none" ? (
                    <div className="mt-4 w-full text-sm">
                        <DetailSection
                            title=""
                            details={(
                                [
                                    shipment.shipmentDetails.packages[0].batteries
                                        ? {
                                              label: (
                                                  <>
                                                      <Battery className="mr-2 h-4 w-4" />
                                                      You have indicated that this shipment contains batteries.
                                                  </>
                                              ),
                                              value: ""
                                          }
                                        : null,
                                    shipment.shipmentDetails.signature != "none"
                                        ? {
                                              label: (
                                                  <>
                                                      <ClipboardPen className="mr-2 h-4 w-4" />
                                                      {shipment.shipmentDetails.signature && capitalizeFirstLetter(shipment.shipmentDetails.signature)} signature has been requested upon delivery.
                                                  </>
                                              ),
                                              value: ""
                                          }
                                        : null
                                ] as { label: React.ReactNode; value: React.ReactNode }[]
                            ).filter(Boolean)}
                        />
                    </div>
                ) : null}
                {shipment.shipmentDetails.returnDetails && shipment.shipmentDetails.returnDetails.isReturnShipment && (
                    <DetailSection
                        title=""
                        details={
                            [
                                {
                                    label: (
                                        <>
                                            <Undo2 className="mr-2 h-4 w-4" />
                                            This is a return shipment.
                                        </>
                                    ),
                                    value: ""
                                }
                            ] as { label: React.ReactNode; value: React.ReactNode }[]
                        }
                    />
                )}
                <hr className="my-4" />
                {shipment.receiver[0].email ? (
                    <DetailSection
                        title="Customer Information"
                        details={[
                            { label: "Customer", value: shipment.receiver[0].attentionName },
                            { label: "Email", value: shipment.receiver[0].email },
                            { label: "Phone", value: shipment.receiver[0].phoneNumber }
                        ]}
                    />
                ) : (
                    <DetailSection
                        title="Customer Information"
                        details={[
                            { label: "Customer", value: shipment.receiver[0].attentionName },
                            { label: "Phone", value: shipment.receiver[0].phoneNumber }
                        ]}
                    />
                )}

                {type !== "confirm" && charge && (
                    <>
                        <hr className="my-4" data-testid="payment-details" />
                        <DetailSection
                            title="Payment Information"
                            details={[
                                {
                                    label: (
                                        <>
                                            <CreditCard className="mr-2 h-5 w-5" />
                                            {charge && charge.payment_method_details.card.brand.toUpperCase()}
                                        </>
                                    ),
                                    value: charge && "**** **** **** " + charge.payment_method_details.card.last4
                                },
                                {
                                    label: "Receipt",
                                    value: charge && (
                                        <a className="text-brand-500 hover:text-brand-400" href={charge.receipt_url} target="_blank" rel="noopener noreferrer">
                                            View Receipt
                                        </a>
                                    )
                                }
                            ]}
                        />
                    </>
                )}
            </div>
            {/* <div className="flex w-full items-center justify-between rounded-b-lg bg-gray-100 p-4">
                <p className="text-sm text-gray-500">Updated May 18, 2024</p>
            </div> */}
        </div>
    );
}
