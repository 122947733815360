import { ColumnDef, Row } from "@tanstack/react-table";
import { Address, Customer, Shipment } from "@shared/ShipmentTypes";
import { Button } from "@/components/ui/button";
import { CircleX, MoreHorizontal } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { getAddress, removeAddress } from "@/api/addresses";
import { useState } from "react";
import { getPackage, removePackage } from "@/api/packages";
import { UseIndexContext } from "@/context/SelectedIndex";
import { useFormContext } from "react-hook-form";
import { usePackageDialogContext } from "@/context/PackageDialog";

export type PackageTableHeader = {
    description: string;
    weight: number;
    length: number;
    width: number;
    height: number;
};

export const columns: ColumnDef<PackageTableHeader>[] = [
    {
        accessorKey: "description",
        header: "Description"
    },
    {
        accessorKey: "weight",
        header: "Weight"
    },
    {
        accessorKey: "length",
        header: "Length"
    },
    {
        accessorKey: "width",
        header: "Width"
    },
    {
        accessorKey: "height",
        header: "Height"
    },
    // {
    //     id: "actions",
    //     cell: ({ row }) => {
    //         return (
    //             <>
    //                 <Button
    //                     variant={"link"}
    //                     onClick={async (e) => {
    //                         e.stopPropagation();
    //                         const index = parseInt(row.id);
    //                         const packages = await getPackage(index);
    //                         packages && removePackage(packages);
    //                     }}>
    //                     <CircleX className="h-4 w-4" />
    //                 </Button>
    //             </>
    //         );
    //     }
    // }
    {
        id: "actions",
        // header: "Action"
        cell: ({ row }) => {
            const { setValue } = useFormContext();

            const selectedIndex = UseIndexContext();

            const { setOpen } = usePackageDialogContext();

            return (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Actions</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const index = parseInt(row.id);
                                    const packages = await getPackage(index);
                                    packages && removePackage(packages);
                                }}>
                                Remove Package
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const index = parseInt(row.id);
                                    const packages = await getPackage(index);
                                    if (packages) {
                                        setValue(`packages.${selectedIndex}.description`, packages.description);
                                        setValue(`packages.${selectedIndex}.weight`, packages.weight);
                                        setValue(`packages.${selectedIndex}.length`, packages.length);
                                        setValue(`packages.${selectedIndex}.width`, packages.width);
                                        setValue(`packages.${selectedIndex}.height`, packages.height);
                                        // setValue(`packages.${selectedIndex}.batteries`, packages.batteries);
                                        setOpen(false);
                                    }
                                }}>
                                Use Package
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            );
        }
    }
];
