import React, { useState } from 'react'

import {
    keepPreviousData,
    useQuery,
} from '@tanstack/react-query'

import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    ColumnFiltersState,
    getFilteredRowModel,
    SortingState,
    getSortedRowModel
} from '@tanstack/react-table'

import { useUserStore } from '@/core/UserStore'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '@/core/firebase'
import { Shipment, ShipmentsTableHeader } from '@shared/ShipmentTypes'
import { UpdateShipmentsStatusesResponse } from '@shared/ResponseTypes'
import { updateShipmentsStatuses } from '@/api/shipments/updateShipment'

import {
    rankItem,
} from '@tanstack/match-sorter-utils'

import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import { statusIconMapping, statusStyleMapping } from "@/lib/statusMapping";
import { trackShipment, voidShipment } from "@/api/shipments/updateShipment";
import { toast } from "@/components/ui/use-toast";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { getShipmentByID } from "@/api/shipments/getShipment";
import dayjs from "dayjs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Input } from '@/components/ui/input'
import { CheckIcon, MixerHorizontalIcon, RowsIcon } from "@radix-ui/react-icons";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronLeft, ChevronRight, ChevronsRight, ChevronsLeft, PackagePlus, RotateCw, MoreHorizontal, ChevronsUpDown, Copy, AlertTriangle  } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Skeleton } from '@/components/ui/skeleton'

/**
* Renders a cell with copyable content in a table column.
*
* @param {Object} params - The parameters for the function.
* @param {Object} params.cell - The cell object containing the value to be displayed and copied.
*
* @returns {JSX.Element} A JSX element representing the copyable column cell.
*/
export const copyableColumn = ({ cell }) => {
    const [copy, setCopy] = useState(false);

    return (
        <div className="flex items-center" onMouseEnter={() => setCopy(true)} onMouseLeave={() => setCopy(false)}>
            <span className="">{cell.getValue()}</span>
            <Copy
                className={`ml-3 h-3 text-gray-500 ${copy ? "hover:text-primary" : "invisible"}`}
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(cell.getValue() as string);
                    toast({
                        description: `${cell.getValue()} copied to clipboard.`
                    });
                }}
            />
        </div>
    );
};

const handleActionItemClick = (e: React.MouseEvent, callback: () => void) => {
    e.stopPropagation();
    callback();
};

const columns: ColumnDef<ShipmentsTableHeader>[] = [
    {
        accessorKey: "id",
        header: "ID",
        cell: ({ cell }) => copyableColumn({ cell }),
    },
    {
        accessorKey: "carrier",
        header: ({ column }) => {
            return (
                <Button className="" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Carrier
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            return (
                <div>
                    <Badge className="bg-gray-700">{row.original.carrier}</Badge>
                </div>
            );
        },
        size: 100,
    },
    {
        accessorKey: "customerName",
        header: ({ column }) => {
            return (
                <div className="">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Name
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{row.original.customerName}</div>;
        },
    },
    {
        accessorKey: "date",
        header: ({ column }) => {
            return (
                <div className="">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Date and Time
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{dayjs(row.original.date).format("MMM D YYYY | hh:mm A")}</div>;
        },
        sortingFn: (rowA, rowB) => {
            const dateA = rowA.original.date ? new Date(rowA.original.date).getTime() : Number.MIN_SAFE_INTEGER;
            const dateB = rowB.original.date ? new Date(rowB.original.date).getTime() : Number.MIN_SAFE_INTEGER;

            return dateA - dateB;
        }
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <div>
                    Status
                </div>
            );
        },
        cell: ({ row }) => {
            const { status, statusLoading, hasError, carrier, trackingNumber } = row.original;

            return (
                <div className={"flex items-center " + statusStyleMapping[status]} onClick={e => e.stopPropagation()}>
                    {statusLoading && <div className="mr-2 w-4 h-4 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>}
                    {!statusLoading && hasError && (
                        <TooltipProvider delayDuration={0}>
                            <Tooltip>
                                <TooltipTrigger>
                                    <AlertTriangle className="mr-2 h-4 w-4 text-yellow-500" />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <span>Couldn't update status. </span>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            trackShipment(carrier, trackingNumber)
                                        }}
                                        className="text-blue-500 underline"
                                    >
                                        Try checking the carrier's website.
                                    </button>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                    {statusIconMapping[status]}
                    {status}


                </div>
            );
        },
    },

    {
        accessorKey: "trackingNumber",
        header: ({ column }) => {
            return (
                <div>Carrier Tracking #</div>
            );
        },
        cell: ({ cell }: { cell: any }) => {
            const [copy, setCopy] = useState(false);
        
            return (
                <div className="flex items-center" onMouseEnter={() => setCopy(true)} onMouseLeave={() => setCopy(false)}>
                    <span>{cell.getValue()}</span>
                    <Copy
                        className={`ml-3 h-3 text-gray-500 ${copy ? "hover:text-primary" : "invisible"}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(cell.getValue() as string);
                            toast({
                                description: `${cell.getValue()} copied to clipboard.`
                            });
                        }}
                    />
                </div>
            );
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const navigate = useNavigate();
            const [resolved, setResolved] = useState(row.original.status === "Cancelled");

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Actions</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={(e) => handleActionItemClick(e, () => navigate(`/shipments/id/${row.original.id}`))}>View details</DropdownMenuItem>
                        <DropdownMenuItem onClick={(e) => handleActionItemClick(e, () => trackShipment(row.getValue("carrier"), row.getValue("trackingNumber")))}>Track</DropdownMenuItem>
                        <DropdownMenuItem
                            disabled={resolved}
                            onClick={(e) =>
                                handleActionItemClick(e, async () => {
                                    try {
                                        const shipment = await getShipmentByID(row.original.id);
                                        if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id !== null) {
                                            const pickupData = await getPickupByShipmentID(shipment.id);
                                            await cancelPickup(pickupData);
                                            await updatePickupStatus(pickupData.id, shipment.id, { status: "Cancelled" });
                                            await removePickupFromShipment(shipment.id);
                                        }
                                        await voidShipment(shipment.serviceDetails?.carrier, shipment.trackingNumber, shipment.id);
                                        await createRefund(shipment.paymentSessionID);
                                        setResolved(true); // Disables the void dropdown menu item
                                        toast({ className: "bg-success-400", title: "Shipment voided successfully." });
                                    } catch (e) {
                                        toast({ variant: "destructive", title: "Failed to void shipment.", description: "Error: " + e.message });
                                    }
                                })
                            }>
                            Void
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={(e) =>
                                handleActionItemClick(e, async () => {
                                    try {
                                        const shipment = await getShipmentByID(row.original.id);
                                        if (!shipment.labelURL) throw new Error("Unable to find an associated label.");
                                        const downloadLink = document.createElement("a");
                                        downloadLink.href = shipment.labelURL;
                                        downloadLink.download;
                                        downloadLink.target = "_blank";
                                        downloadLink.click();
                                    } catch (e) {
                                        toast({ variant: "destructive", title: "Failed to download label.", description: "Error: " + e.message });
                                    }
                                })
                            }>
                            Download label
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    }
];

// A typical debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}

export function ShipmentsTable({ initialColumnFiltersState = [], pageSize = 10 }: { initialColumnFiltersState?: { id: string , value: string }[], pageSize?: number }) {
    const user = useUserStore((state) => state.user);
    const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: true }]);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState({ v: Date.now() }) // reference used to trigger data refresh

    const [loadingIndicator, setLoadingIndicator] = useState(false)

    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize,
    })
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(initialColumnFiltersState)

    async function fetchMyData(options: {
        pageIndex: number
        pageSize: number
    }, filters: ColumnFiltersState, sorting: SortingState) {
        if (user) {
            try {
                // TODO: paginate this as well
                // TODO: optimize this so we dont fetch on every page/pageSize/filter change
                // TODO: move sorting and filtering to the firebase level
                let q = query(collection(db, "shipments"), where("uid", "==", user.email), orderBy("date", "desc"));

                const snapshot = await getDocs(q);
                let shipments: ShipmentsTableHeader[] = [];

                snapshot.forEach((doc) => {
                    const data = doc.data() as Shipment;

                    shipments.push({
                        id: doc.id,
                        carrier: data.serviceDetails?.carrier || "N/A",
                        customerName: data.receiver[0].attentionName,
                        status: data.status || "Label Created",
                        trackingNumber: data.trackingNumber || "N/A",
                        date: data.date,
                        statusLastChecked: data.statusLastChecked
                    });
                });

                if (filters.length > 0) {
                    for (const filter of filters) {
                        if (filter.id === "status" && filter.value !== "") {
                            shipments = shipments.filter(shipment => shipment[filter.id] === filter.value);
                        } else if (filter.id === "id" && filter.value !== "") {
                            shipments = shipments.filter(shipment => {
                                // https://tanstack.com/table/latest/docs/framework/react/examples/filters-fuzzy
                                const itemRank = rankItem(shipment.id, filter.value as string)
        
                                // Return if the item should be filtered in/out
                                return itemRank.passed
                            })
                        }
                    }
                }

                if (sorting.length === 0 || sorting[0].id === "date") {
                    if (sorting.length === 0 || sorting[0].desc) {
                        shipments.sort(((a, b) => {
                            const dateA = a.date ? new Date(a.date).getTime() : 0;
                            const dateB = b.date ? new Date(b.date).getTime() : 0;
                
                            return dateB - dateA;
                        }))
                    } else {
                        shipments.sort(((a, b) => {
                            const dateA = a.date ? new Date(a.date).getTime() : 0;
                            const dateB = b.date ? new Date(b.date).getTime() : 0;
                
                            return dateA - dateB;
                        }))
                    }
                } else if (sorting[0].id === "carrier") {
                    if (sorting[0].desc) {
                        shipments.sort((a, b) => b.carrier.localeCompare(a.carrier));
                    } else {
                        shipments.sort((a, b) => a.carrier.localeCompare(b.carrier));
                    }
                } else if (sorting[0].id === "customerName") {
                    if (sorting[0].desc) {
                        shipments.sort((a, b) => b.customerName.localeCompare(a.customerName));
                    } else {
                        shipments.sort((a, b) => a.customerName.localeCompare(b.customerName));
                    }
                }

                let paginatedShipments = shipments.slice(
                    options.pageIndex * options.pageSize,
                    (options.pageIndex + 1) * options.pageSize
                )
                
                const shouldUpdateStatus = paginatedShipments.find(shipment => {
                    if (shipment.status === "Delivered" || shipment.status === "Cancelled") {
                        return false
                    }

                    if (!shipment.statusLastChecked) {
                        return true
                    }

                    const statusLastChecked = (new Date(shipment.statusLastChecked)).getTime()
                    const fiveMinutesAgoTimestamp = Date.now() - 5 * 60 * 1000;
                    const fiveMinutesHasNotPassed = statusLastChecked > fiveMinutesAgoTimestamp

                    if (fiveMinutesHasNotPassed) {
                        return false
                    }
                    
                    return true
                })
                
                if (shouldUpdateStatus) {
                    setLoadingIndicator(true)
                    const updateStatuses = async () => {
                        const shipmentIds: string[] = paginatedShipments.map(shipment => shipment.id)
                        
                        const updateShipmentStatusesResponse: UpdateShipmentsStatusesResponse = await updateShipmentsStatuses(shipmentIds);
        
                        if (updateShipmentStatusesResponse.httpStatusCode === 500 || Object.values(updateShipmentStatusesResponse.data).length === 0) {
                            for (const shipment of paginatedShipments) {
                                shipment.hasError = true
                            }
                        } else {
                            for (const shipment of paginatedShipments) {
                                const result = updateShipmentStatusesResponse.data[shipment.id]
        
                                if (!result) {
                                    shipment.status = shipment.status
                                    shipment.hasError = true
                                } else {
                                    shipment.status = result.status
                                    shipment.hasError = result.hasError
                                }
                            }
                            // needed for updated status to display // TODO: is there a way to optimize this so we dont fetch twice?
                            // careful using this here for a possible infinite loop. we know updateShipmentsStatuses succeeded here and that shouldUpdateStatus would be false right after this, so this would not continue retriggering.
                            setRefresh({ v: Date.now() }) 
                        }
                        setLoadingIndicator(false)
                    }
                    updateStatuses() // dont wait on this so other data can be displayed meanwhile
                }

                return {
                    rows: paginatedShipments,
                    pageCount: Math.ceil(shipments.length / options.pageSize),
                    rowCount: shipments.length,
                }

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }

        return {
            rows: [],
            pageCount: 0,
            rowCount: 0,
        }
    }

    const dataQuery = useQuery({
        queryKey: ['data', pagination, columnFilters, user, refresh, sorting],
        queryFn: () => fetchMyData(pagination, columnFilters, sorting),
        placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
    })

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: dataQuery.data?.rows ?? defaultData,
        columns,
        // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
        rowCount: dataQuery.data?.rowCount, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
        state: {
            pagination,
            columnFilters,
            sorting
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),


        manualPagination: true, //we're doing manual "server-side" pagination
        // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
        // debugTable: true,
    })


    return (
        <div className="p-2">
            <div className="flex items-center justify-between pb-4 ">
                <DebouncedInput
                    type="text"
                    value={(table.getColumn("id")?.getFilterValue() ?? '') as string}
                    onChange={value => table.getColumn("id")?.setFilterValue(value)}
                    placeholder={`Search by ID...`}
                    className="w-36 border shadow rounded"
                />
                <div className="flex space-x-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="hidden h-8 lg:flex"> {/* Fixed width */}
                                <RowsIcon className="mr-2 h-4 w-4" />
                                Status Filter
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-[200px]">
                            {["", "Label Created", "In-Transit", "Delay / Warning", "Cancelled", "Delivered", "Refer to tracking link"].map((status) => (
                                <DropdownMenuItem
                                    key={status}
                                    onClick={() => {
                                        table.getColumn("status")?.setFilterValue(status)
                                        table.setPageIndex(0)
                                    }}
                                    className="flex justify-between"
                                >
                                    {status === "" ? "All" : status}
                                    {table.getColumn("status")?.getFilterValue() === status && <CheckIcon className="h-4 w-4" />}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto hidden h-8 lg:flex"
                        disabled={loadingIndicator}
                        onClick={async () => {
                            setRefresh({v: Date.now()})
                        }}>
                        <RotateCw className={`mr-2 h-4 w-4 ${loadingIndicator ? " animate-spin" : ""}`} />
                        Refresh
                    </Button>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
                                <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                                View
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-[150px]">
                            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {table
                                .getAllColumns()
                                .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
                                .map((column) => {
                                    return (
                                        <DropdownMenuCheckboxItem key={column.id} className="capitalize" checked={column.getIsVisible()} onCheckedChange={(value) => column.toggleVisibility(!!value)}>
                                            {column.id === "customerName" ? "Name" : column.id === "id" ? "ID" : column.id === "trackingNumber" ? "Tracking #" : column.id}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return <TableHead key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</TableHead>;
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {/*{!loadingIndicator ?*/} {(
                            table.getFilteredRowModel().rows?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className="h-24 text-center">
                                        <p className="text-lg">You have no shipments.</p>
                                        <Button variant="outline" className="mt-3" onClick={() => navigate("/create-shipment")}>
                                            <PackagePlus className="mr-2 h-4 w-4" />
                                            Create a shipment!
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                table.getFilteredRowModel().rows.map((row, index) => (
                                    <TableRow key={index} data-state={row.getIsSelected() && "selected"} onClick={() => navigate(`/shipments/id/${row.getValue("id")}`)} className="cursor-pointer">
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                cell.id.includes("status") && loadingIndicator ? <TableCell key={`skeleton-cell-${index}`}>
                                                <div className={`flex items-center`}>
                                                    <Skeleton className="h-5 w-full" />
                                                </div>
                                            </TableCell> : <TableCell key={cell.id} className="overflow-x-auto">{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))
                            )
                        )} {/*: (
                            Array.from({ length: table.getState().pagination.pageSize }).map((_, index) => (
                                <TableRow key={`skeleton-${index}`}>
                                    {columns.map((col, colIndex) => (
                                        <TableCell key={`skeleton-cell-${index}-${colIndex}`}>
                                            <div className={`flex items-center h-[35px] w-[${col.size}px]`}>
                                                <Skeleton className="h-5 w-full" />
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )} */}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="text-muted-foreground flex-1 text-sm">{/* {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s) selected. */}</div>
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            const newPageSize = Number(value);
                            table.setPageSize(newPageSize);
                            table.setPageIndex(0);
                        }}>
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={table.getState().pagination.pageSize} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </div>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.setPageIndex(0);
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <span className="sr-only">Go to first page</span>
                    <ChevronsLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.previousPage();
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <ChevronLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        return table.nextPage();
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to next page</span>
                    <ChevronRight className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        const lastPage = table.getPageCount() - 1;
                        return table.setPageIndex(lastPage);
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to last page</span>
                    <ChevronsRight className="h-4 w-4" />
                </Button>
            </div>
        </div>
    )
}


