import { useEffect, useState } from "react";
import { get, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { useStepper } from "@/components/ui/stepper";
import useRatesStore from "@/core/RatesStore";
import { useFormStore } from "@/core/ShipmentFormStore";
import { shipmentDetailsSchema, shipmentSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";

import Form from "@/components/composite/Form";
import CustomsDetails from "./CustomsDetails";
import LetterDetails from "./shipment-details/LetterDetails";
import PackageDetails from "./shipment-details/PackageDetails";
import ShipmentInformation from "./shipment-details/ShipmentDescription";
import ShipmentType from "./shipment-details/ShipmentType";

import { useFormSteps } from "@/context/FormStepContext";
import { Shipment } from "@shared/ShipmentTypes";
import TubeDetails from "./shipment-details/TubeDetails";
import { useUserStore } from "@/core/UserStore";

export default function ShipmentDetails() {
    const { prevStep, nextStep } = useStepper();

    const { setFormSteps, setSteps, steps, FormSteps } = useFormSteps();

    const [shipmentType, setShipmentType] = useState("");

    const PackagingDetails = {
        Letter: LetterDetails,
        Package: PackageDetails,
        Tube: TubeDetails
    };

    const formValues = useFormStore.getState().shipmentDetails;

    const methods = useForm<z.infer<typeof shipmentDetailsSchema>>({
        resolver: zodResolver(shipmentDetailsSchema),
        mode: "onChange",
        defaultValues: { ...formValues, confirmation: false }
    });

    console.log(methods.formState.errors);

    useEffect(() => {
        // * clear store
        useRatesStore.getState().removeRates();
        useFormStore.getState().removeCustomsDetails();

        // * set values for toggles
        methods.setValue("units", formValues.units ?? "imperial");
        methods.setValue("signature", formValues.signature ?? "none");

        methods.setValue("packages", formValues.packages);
    }, []);

    const onSubmit = async (data: z.infer<typeof shipmentDetailsSchema>) => {
        useFormStore.getState().addShipmentDetails(data);

        useFormStore.getState().addPickupDetails({
            pickupType: "pickup",
            scheduleType: "scheduleLater"
        });

        if (checkForCustoms(useFormStore.getState().shipment)) {
            if (steps[5].label !== "Customs") {
                let newSteps = [...steps];
                let newFormSteps = [...FormSteps];
                newSteps.splice(5, 0, { label: "Customs" });
                setSteps(newSteps);

                newFormSteps.splice(5, 0, <CustomsDetails />);
                setFormSteps(newFormSteps);
                console.log(newFormSteps);
            }

            // ! add preexisting customs details
            let customsValues = useFormStore.getState().customsDetails;

            useFormStore.getState().addCustomsDetails({
                exportReason: "SALE",
                products: [
                    {
                        description: "1",
                        quantity: 1,
                        units: "PACKAGE",
                        numberOfPackages: 1,
                        value: 1,
                        weight: 1,
                        country: "CA"
                    }
                ],
                customsPayor: "shipper",
                customsCurrency: "CAD"
            });
        } else if (steps[5].label === "Customs") {
            console.log(steps);
            let newSteps = [...steps];
            let newFormSteps = [...FormSteps];

            newSteps.splice(5, 1);
            setSteps(newSteps);
            newFormSteps.splice(5, 1);
            setFormSteps(newFormSteps);
        }
        // console.log(useFormStore.getState().shipment);

        const shipment = useFormStore.getState().shipment;

        if (!shipment.shipper.email) {
            useFormStore.getState().addShipper({ ...useFormStore.getState().shipment.shipper, email: useUserStore.getState().user?.email as string });
        }

        if (!shipment.shipmentDetails.packages[0].description) {
            const shipmentDetails = shipment.shipmentDetails;
            shipmentDetails.packages[0].description = methods.getValues("description");
            useFormStore.getState().addShipmentDetails(shipmentDetails);
        }

        const validation = validateShipment(useFormStore.getState().shipment);

        if (validation) {
            nextStep();
        }
    };

    const goPrev = () => {
        const data = methods.getValues();
        useFormStore.getState().addShipmentDetails(data);
        prevStep();
    };

    const errorMessage = get(methods.formState.errors, "root")?.message;

    const Details = PackagingDetails[shipmentType];

    return (
        <>
            <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                {errorMessage !== undefined ? <p className="text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
                {/* {needCustoms && (
                    <>
                            <AlertDescription>
                                <Info className="inline h-4 w-4 align-middle" /> If you are shipping something other than documents, please select the, Courier, Pak, or Package option. Please note
                                that this service only allows for the shipment of one envelope at a time, and that some countries do not accept specific types of documents (such as passport, identity
                                card, credit card, currency) as a document shipment.
                            </AlertDescription>
                        </Alert>
                    </>
                )} */}
                <ShipmentType shipmentType={shipmentType} setShipmentType={setShipmentType} />
                {Details && <Details />}
                <ShipmentInformation />
                <div className="flex gap-6">
                    <Button type="button" onClick={goPrev} variant="outline" className="flex-grow">
                        Back
                    </Button>

                    <Button variant="default" type="submit" className="flex-grow">
                        {/* Find Rates */}
                        Next
                    </Button>
                </div>
            </Form>
        </>
    );
}

const validateShipment = (shipment: Shipment) => {
    let isValid = false;
    try {
        const validation = shipmentSchema.parse(shipment);
        console.log("Validation successful:", validation);
        isValid = true;
    } catch (error) {
        console.error("Validation failed:", error.errors);
    }
    return isValid;
};

const checkForCustoms = (shipment: Shipment) => {
    let isCustoms = false;

    console.log(shipment);

    if (shipment.receiver[0].address.countryCode !== "CA" || shipment.shipper.address.countryCode !== "CA") {
        if (shipment.shipmentDetails.shipmentType === "Package" || (shipment.shipmentDetails.shipmentType === "Letter" && shipment.shipmentDetails.deliveryFormat === "pak")) {
            isCustoms = true;
        }
    }

    // console.log(isCustoms);

    return isCustoms;
};
