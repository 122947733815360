// * import components and pages
import LoginForm from "@/app/auth/LoginPage";
import SignUpForm from "@/app/auth/SignUp";
import ShipmentSuccess from "@/app/shipment/ShipmentSuccess";
import CreateShipment from "@/app/shipment/CreateShipmentPage";
import AccountSettingsLayout from "@/components/layout/SettingsLayout";
import PaymentsForm from "@/app/user/settings/Payments";
import InfoForm from "@/app/user/settings/Info";
import ShipmentsLayout from "@/app/user/shipments/page";
import ShipmentDetails from "@/app/user/shipments/ShipmentInformation";

// * import layouts
import MainLayout from "@/components/layout/MainLayout";
import CarriersForm from "@/app/user/settings/Carriers";

import ResetPassword from "@/app/auth/ResetPassword";
import GetQuote from "@/app/shipment/GetQuote";
import ErrorPage from "@/app/ErrorPage";
import AddressLayout from "@/app/user/address/page";
import DropoffLocation from "@/components/composite/Cards/DropoffLocations";
import AdminLogin from "@/app/auth/AdminLogin";
import Contact from "@/app/Contact";
import PhoneNumberTest from "@/app/PhoneNumber";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import TrackingPage from "@/app/user/TrackingPage";
import HomeLayout from "@/app/user/home/HomePage";

export default function AppRoutes() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <MainLayout />
                    </ProtectedRoute>
                }>
                {/* <Route index element={<Navigate to="create-shipment" replace={true} />} /> */}
                <Route index element={<HomeLayout />} />

                <Route path="create-shipment/:confirmShipment?" element={<CreateShipment />} />

                {/* <Route index element={<CreateShipment />} />
                    <Route path="confirmation" element={<ConfirmShipment />} />
                <Route path="shipment-success" element={<OrderComplete />} /> */}
                <Route path="shipment-success/:id" element={<ShipmentSuccess />} />
                <Route path="shipments">
                    <Route index element={<ShipmentsLayout />} />
                    <Route path="id/:id" element={<ShipmentDetails />} />
                    <Route path="status/:status" element={<ShipmentsLayout />} />
                </Route>

                {/* <Route path="billing/:tabParam?" element={<BillingLayout />} /> */}
                <Route path="shipments/?id=?:id" element={<ShipmentDetails />} />
                <Route path={"track-shipment"} element={<TrackingPage />} />
                {/* <Route path="/login" element={<LoginForm />} />
                <Route path="/sign-up" element={<SignUpForm />} /> */}
                {/* <Route path="rates" element={<Rates />} /> */}

                <Route path="dropoff" element={<DropoffLocation carrier="FedEx" />} />
                <Route path="support" element={<Contact />} />
                <Route path="address" element={<AddressLayout />} />
                {/* <Route path="products" element={<ProductsLayout />} /> */}
                <Route path="/*" element={<ErrorPage />} />
            </Route>
            <Route path="/pnu" element={<PhoneNumberTest />} />
            <Route
                path="/settings"
                element={
                    <ProtectedRoute>
                        <AccountSettingsLayout />
                    </ProtectedRoute>
                }>
                <Route index element={<InfoForm />} />
                <Route path="payments" element={<PaymentsForm />} />
                <Route path="carriers" element={<CarriersForm />} />
            </Route>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/sign-up" element={<SignUpForm />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/get-a-quote" element={<GetQuote />} />
        </Routes>
    );
}
