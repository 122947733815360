import axios from "axios";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/core/firebase";
import { mapStatus } from "@/lib/statusMapping";
import { Shipment, Status } from "@shared/ShipmentTypes";
import { getToken } from "../auth";
import { UpdateShipmentsStatusesResponse } from "@shared/ResponseTypes";

// Accessing carrier status codes
const carrierStatusMapping = {
    ups: (response) => mapStatus(response.data.trackResponse.shipment[0].package[0].currentStatus.code, "ups"),
    fedex: (response) => mapStatus(response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.code, "fedex"),
    purolator: (response, previousStatus?) => mapStatus(response.data.TrackingInformationList.TrackingInformation[0].Scans.Scan[0].Description, "purolator", previousStatus),
    gls: (response) => mapStatus(response.data.activities[0].code, "gls")
    // Add more carriers as needed
};

const voidShipment = async (carrier: string | undefined, trackingNumber: string | undefined, id: string | undefined) => {
    try {
        if (!id || !trackingNumber || !carrier) throw new Error("Undefined shipment properties; unable to void shipment.");

        // Cancel the shipment on the carrier's end
        await axios.put(`${import.meta.env.VITE_API_URL}/${carrier}/void-shipment/${trackingNumber}`);

        // Set the shipment status to cancelled in our Firestore database
        const shipment = doc(db, "shipments", id);
        await updateDoc(shipment, {
            status: "Cancelled"
        });
    } catch (e) {
        throw e;
    }
};

const trackShipment = (carrier: string | undefined, trackingNumber: string | undefined) => {
    try {
        if (carrier) {
            let url = "";

            if (carrier.toLowerCase() == "ups") {
                url = `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
            } else if (carrier.toLowerCase() == "fedex") {
                url = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
            } else if (carrier.toLowerCase() == "purolator") {
                url = `https://www.purolator.com/en/shipping/tracker?pin=${trackingNumber}`;
            } else if (carrier.toLowerCase() == "gls") {
                url = `https://gls-group.com/CA/en/send-and-receive/track-a-shipment/?match=${trackingNumber}`;
            }

            window.open(url, "_blank");
            return url;
        }
    } catch (e) {
        console.error(e);
    }
};

const updateAllShipmentsStatus = async (querySnapshot) => {
    querySnapshot.forEach(async (doc) => {
        let updatedStatus = "";
        try {
            // Guard clause to check if shipment has been resolved in any way
            if (doc.data().status == "Cancelled" || doc.data().status == "Delivered") throw new Error("Shipment is already cancelled or delivered; unable to update status.");

            // Make a request to the carrier's API to get the latest status of the shipment
            const response = await axios.get(`${import.meta.env.VITE_API_URL}/${doc.data().serviceDetails.carrier}/track-shipment/${doc.data().trackingNumber}`);

            // Update the shipment status based on the carrier's response
            if (carrierStatusMapping[doc.data().serviceDetails.carrier.toLowerCase()]) {
                updatedStatus = carrierStatusMapping[doc.data().serviceDetails.carrier.toLowerCase()](response, doc.data().serviceDetails.carrier.toLowerCase() === "purolator" && doc.data().status);
            }

            // Update the shipment's status in Firestore
            await updateDoc(doc.ref, {
                status: updatedStatus
            });
        } catch (e) {
            throw e;
        }
    });
};

const trackShipmentBackend = async (shipment: Shipment) => {
    const statusList: Status[] = [];
    const trackingNumber = shipment.trackingNumber as string;

    if (shipment && shipment.serviceDetails) {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/${shipment.serviceDetails.carrier}/track-shipment/${trackingNumber}`);
        console.log(response);
        if (shipment.serviceDetails.carrier.toLowerCase() == "ups") {
            mapStatus(response.data.trackResponse.shipment[0].package[0].currentStatus.code, "ups");
        } else if (shipment.serviceDetails.carrier.toLowerCase() == "fedex") {
            mapStatus(response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.code, "fedex");
        } else if (shipment.serviceDetails.carrier.toLowerCase() == "purolator") {
            (response.data.TrackingInformationList.TrackingInformation[0].Scans.Scan as Object[]).forEach((scan) => {
                const currentStatus = mapStatus(scan["Description"], "purolator");
                statusList.push(currentStatus);
            });
        }
    }

    return statusList;
};


export async function updateShipmentsStatuses(shipmentIds: string[]): Promise<UpdateShipmentsStatusesResponse> {
    try {
        const token = await getToken();

        const result: UpdateShipmentsStatusesResponse = await axios({
            method: "patch",
            url: `${import.meta.env.VITE_API_URL}/shipments/status`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: shipmentIds
        });

        return result
    } catch (error) {
        console.error("Error updating shipment table statuses: ", error);
        return { httpStatusCode: 500, data: {} };
    }
}


export { voidShipment, trackShipment, updateAllShipmentsStatus, trackShipmentBackend };
