import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useUserStore } from "@/core/UserStore";
import { doc, updateDoc, getDocs, query, collection } from "firebase/firestore";
import { db } from "@/core/firebase";

import { ChevronRight, Megaphone, MoveRight, X } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export interface BannerProps {
    id: string;
    text: string;
    component?: string | ReactNode;
    isActive: boolean;
}

export function Banner({ id, text, component, isActive }: BannerProps) {
    const userData = useUserStore((state) => state.userData);
    const editData = useUserStore((state) => state.editUserData);

    const [open, setOpen] = useState(!userData?.settings?.closeBanners?.[id] && isActive);

    const handleClick = async () => {
        setOpen(false);
        const updatedSettings = {
            settings: {
                ...userData?.settings,
                closeBanners: {
                    ...userData?.settings?.closeBanners,
                    [id]: true
                }
            }
        };
        editData(updatedSettings);

        const userRef = doc(db, "users", userData?.email as string);
        await updateDoc(userRef, updatedSettings);
    };

    return open ? (
        <Alert className="bg-brand-900 mb-4 grid items-center align-middle text-white" key={id}>
            <Megaphone className="h-4 w-4 text-white" color="white" />
            {/* <MoveRight className="h-4 w-4" color /> */}
            <AlertTitle className="flex w-full">
                Heads up! <X className="ml-auto h-4 w-4" onClick={handleClick} />
            </AlertTitle>
            <AlertDescription className="h-full">{component ? component : text}</AlertDescription>
        </Alert>
    ) : (
        <></>
    );
}

const NewBanner: BannerProps = {
    id: "new-home-page",
    text: "Our new homepage dashboard is live! Click 'Home' to manage shipments, schedule pickups, and more—all in one place.",
    component: (
        <p>
            Our new homepage dashboard is live! Click 'Home' to manage shipments, schedule pickups, and more—all in one place.
        </p>
    ),
    isActive: true
};

export function BannerWrapper() {
    const [banners, setBanners] = useState<BannerProps[] | null>();

    const queryForBanners = async () => {
        try {
            const q = query(collection(db, "banners"));

            const querySnapshot = await getDocs(q);

            const bannerList = querySnapshot.docs.map((doc) => {
                const data = doc.data() as BannerProps;
                return data;
            });

            return bannerList;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    useEffect(() => {
        queryForBanners().then((bannerList) => {
            if (bannerList) {
                const updatedBanners = [...bannerList, NewBanner];
                setBanners(updatedBanners);
            } else {
                setBanners([NewBanner]);
            }
        });
    }, []);

    return (
        <>
            {banners &&
                banners.map((banner) => {
                    return <Banner text={banner.text} component={banner.component} id={banner.id} isActive={banner.isActive} key={banner.id} />;
                })}
        </>
    );
}
