import axios from "axios";
import { GetQuoteSchema } from "@/schema/ShipmentSchema";
import { z } from "zod";

export const sendQuoteInfo = async (data: z.infer<typeof GetQuoteSchema>) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/email/send-quote-info`, data);
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};