import { Card, CardContent, CardHeader } from "@/components/ui/card";
import AddressForm, { CustomerContext } from "@/components/composite/AddressForm";
import Form from "@/components/composite/Form";
import FormTitle from "@/components/composite/Headers/FormTitle";
import Checkbox from "@/components/composite/Inputs/Checkbox";
import { useStepper } from "@/components/ui/stepper";
import { useSaveAddressStore } from "@/core/SaveAddress";
import { useFormStore } from "@/core/ShipmentFormStore";
import { customerSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormFooter } from "../CreateShipmentPage";

export default function ShipperForm() {
    const formValues = useFormStore.getState().shipper;
    const methods = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        // mode: "onChange",
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: formValues
    });

    const { nextStep } = useStepper();

    const onSubmit = (data: z.infer<typeof customerSchema>) => {
        console.log(data);
        useFormStore.getState().addShipper(data);
        nextStep();
    };

    const handleSave = async (value: boolean) => {
        // console.log(value);
        useSaveAddressStore.getState().setSaveShipper(value);
    };

    const handleDefault = async (value: boolean) => {
        useSaveAddressStore.getState().setShipperDefault(value);
    };

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Shipper Address</FormTitle>
            </CardHeader>
            <CardContent className="p-4">
                <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                    <CustomerContext.Provider value="shipper">
                        <AddressForm />
                    </CustomerContext.Provider>
                    <div className="grid grid-cols-1 gap-1">
                        {
                            // TODO: DEFAULT VALUE SHOULD BE SAVESHIPPER
                        }

                        <Checkbox name="saveAddress" label="Save this address in address book" onChange={handleSave} defaultValue={useSaveAddressStore.getState().saveShipper} />
                        <Checkbox name="defaultShipper" label="Set this address as the default shipper address" onChange={handleDefault} defaultValue={useSaveAddressStore.getState().shipperDefault} />
                    </div>
                    <FormFooter />
                </Form>
            </CardContent>
        </Card>
    );
}
