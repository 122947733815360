import Input from "@/components/composite/Inputs/Input";
import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import Select from "@/components/composite/Inputs/Select";
import { Label } from "@/components/ui/label";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useFormToggle } from "@/context/FormToggleContext";
import { useFormStore } from "@/core/ShipmentFormStore";
import { Info } from "lucide-react";
import { useState, useEffect, memo, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

const Units = [
    {
        name: "imperial",
        label: "Imperial (lb, in)"
    },
    {
        name: "metric",
        label: "Metric (kg, cm)"
    }
];

const Signature = [
    {
        name: "none",
        label: "No signature"
    },
    {
        name: "regular",
        label: "Regular Signature"
    },
    {
        name: "adult",
        label: "Adult Signature"
    }
];

export default function ShipmentOptions() {
    const { getValues, setValue } = useFormContext();

    return (
        <>
            <RadioButtons options={Units} name="units" defaultValue={getValues("units") || "imperial"} />
            {/* <RadioButtons options={Signature} name="signature" defaultValue={getValues("signature") || "none"} /> */}
            <Select SelectItemList={Signature} name="signature" label="Signature Options" defaultValue={getValues("signature") || "none"} />
        </>
    );
}

export const DeclaredValueTooltip = ({ totalLabel }: { totalLabel?: boolean }) => {
    return (
        <div className="flex items-center justify-start">
            {totalLabel ? "Total Cost Value of the Goods Being Shipped" : "Declared Value of Package"}
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>
                            Your shipment is covered by the carrier up to $100 at no charge under their terms and conditions. For additional protection, enter the total value of your package to
                            purchase coverage against loss or damage, ensuring it matches your manufacturer's or supplier's invoice value to avoid penalties.
                        </p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

export const DeclaredValueInput = ({ index, totalLabel }: { index?: number; totalLabel?: boolean }) => {
    return (
        <div className="grid gap-2">
            <Label htmlFor={`packages.${index ?? 0}.declaredValue`}>
                <DeclaredValueTooltip totalLabel={totalLabel} />
            </Label>
            <div className="flex flex-row items-center gap-2">
                <span className="pb-2">$</span> <Input name={`packages.${index ?? 0}.declaredValue`} type="number" min={0} className="w-full" />
            </div>
        </div>
    );
};

export const DeclaredOptionSelect = () => {
    const { setValue, getValues } = useFormContext();

    return (
        <div className="grid grid-cols-2 gap-4">
            <Select label="Declared Currency" name="declaredCurrency" SelectItemList={DeclaredCurrencyList} defaultValue={getValues("declaredCurrency") ?? "CAD"} />
            <Select label="Insurance Provider" name="insuranceType" SelectItemList={InsuranceList} defaultValue={getValues("insuranceType") ?? "carrier"} />
        </div>
    );
};

export const DeclaredCurrencyList = [
    {
        name: "CAD",
        label: "CAD"
    },
    {
        name: "USD",
        label: "USD"
    }
];

export const InsuranceList = [
    {
        name: "carrier",
        label: "Carrier"
    }
    // * to add once we provide insurance
    // {
    //     name: "snapship",
    //     label: "SnapShip"
    // }
];
