import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { get, useFormContext } from "react-hook-form";
import { useEffect, useState, memo } from "react";
import { useFormStore } from "@/core/ShipmentFormStore";
import ShipmentOptions, { DeclaredCurrencyList, DeclaredOptionSelect, DeclaredValueInput, DeclaredValueTooltip } from "./ShipmentOptions";
import Input from "@/components/composite/Inputs/Input";
import { Label } from "@/components/ui/label";
// import { Checkbox } from "@/components/ui/checkbox";
import Checkbox from "@/components/composite/Inputs/Checkbox";
import { Alert, AlertDescription } from "@/components/ui/alert";
import Select from "@/components/composite/Inputs/Select";

const LetterDetails = memo(function LetterDetails() {
    const { getValues, setValue } = useFormContext();

    const toggleBatteries = (value: boolean) => {
        setValue(`packages.${0}.batteries`, value);
    };

    const [declaredValueToggle, setDeclaredValueToggle] = useState<boolean>(getValues("declaredValueToggle") ?? false);
    const toggleDeclaredValue = (value: boolean) => {
        setDeclaredValueToggle(value);
    };
    return (
        <Card>
            <CardHeader className="grid grid-cols-3 items-center">
                <CardTitle className="text-base font-medium">Letter Details</CardTitle>
                <ShipmentOptions />
            </CardHeader>
            <CardContent className="grid gap-4">
                <DeliveryFormat />
                <Input label="Weight" name="packages.0.weight" type="number" min={0} />
                {getValues("deliveryFormat") === "letter" && <Confirmation />}
                {getValues("deliveryFormat") === "pak" && (
                    <>
                        <Checkbox name={`packages.${0}.batteries`} label="My pak contains batteries" onChange={toggleBatteries} />
                        {/* <div className="grid grid-cols-[3fr_1.5fr_1fr] gap-6"> */}
                        {/* <Input label={"Description"} name={`packages.${0}.description`} /> */}

                        <Checkbox name={`declaredValueToggle`} label="Do you want to purchase additional insurance?" onChange={toggleDeclaredValue} defaultValue={getValues("declaredValueToggle")} />

                        {declaredValueToggle && (
                            <div className="grid grid-cols-[2.5fr_1fr] gap-6">
                                <DeclaredValueInput totalLabel={true} />
                                <DeclaredOptionSelect />
                            </div>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
});

export default LetterDetails;

const DeliveryFormat = () => {
    const {
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const deliveryFormatValue = useFormStore.getState().shipmentDetails.deliveryFormat || getValues("deliveryFormat");

    const defaultValue = () => {
        if (deliveryFormatValue === "letter" || deliveryFormatValue === "pak") {
            return deliveryFormatValue;
        }
        return "letter";
    };

    const handleDeliveryFormatChange = (value: string) => {
        if (value !== "") {
            setValue("deliveryFormat", value);
            setValue("packages.0.length", 0);
            setValue("packages.0.width", 0);
            setValue("packages.0.height", 0);
            clearErrors("deliveryFormat");
            // console.log(getValues());
        }
    };

    const errorMessage = get(errors, "deliveryFormat")?.message;

    useEffect(() => {
        handleDeliveryFormatChange(defaultValue());
    }, []);

    return (
        <>
            <ToggleGroup type="single" className="grid grid-cols-2 gap-4" defaultValue={defaultValue()} onValueChange={handleDeliveryFormatChange}>
                <ToggleGroupItem value="letter" className="flex items-center justify-start gap-2 p-3" name="deliveryFormat">
                    Envelope
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger>
                                <Info className="h-4" />
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-left">
                                <p>Only documents of no commercial value can be shipped with envelopes.</p>
                                <p>Less than 1 lbs or 0.4 kg</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </ToggleGroupItem>
                <ToggleGroupItem value="pak" className="flex items-center justify-start gap-2 p-3" name="deliveryFormat">
                    Pak
                    <TooltipProvider delayDuration={50}>
                        <Tooltip>
                            <TooltipTrigger>
                                <Info className="h-4" />
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-left">
                                <p>Please note that the weight limit for a courier PAK shipment is 3 lbs or 1.36 kg</p>
                                {/* <p>FedEx: No greater than 1 kg or 2.2 lbs</p>
                                    <p>UPS: No greater than 1.5 kg or 3 lbs</p>
                                    <p>Purolator: No greater than 2.2 kg or 5 lb</p> */}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </ToggleGroupItem>
            </ToggleGroup>
            {errorMessage !== undefined ? <p className="text-center text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </>
    );
};

const Confirmation = () => {
    const {
        setValue,
        clearErrors,
        setError,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, "confirmation")?.message;

    const toggleConfirmation = (value: boolean) => {
        if (value) {
            clearErrors(`confirmation`);
        } else {
            setError(`confirmation`, { type: "custom", message: "Please confirm this envelope only contains documents" });
        }
        setValue(`confirmation`, value);
    };

    return (
        <>
            <Checkbox name={`confirmation`} data-testid="confirmation" role="confirmation" label="I confirm this envelope only contains documents" onChange={toggleConfirmation} />

            {/* <div className="flex gap-2">
                <Checkbox name={`confirmation`} id={`confirmation`} onCheckedChange={toggleConfirmation} />
                <Label htmlFor={`confirmation`}>I confirm this envelope only contains documents</Label>
            </div> */}

            <Alert>
                <AlertDescription>
                    <Info className="inline h-4 w-4 align-middle" /> If you need to ship items other than documents, please choose the Pak or Package option. Please note that certain countries may not
                    accept specific types of documents (such as credit cards, passports, ID cards, or currency) as document shipments.
                </AlertDescription>
            </Alert>
        </>
    );
};
