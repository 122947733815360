import { FormEventHandler, MouseEventHandler, ReactNode } from "react";
import { Button as ButtonStructure, buttonVariants } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { VariantProps } from "class-variance-authority";

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    isLoading: boolean;
}

export default function Button({ isLoading, children, onClick, type, className, disabled, variant }: LoadingButtonProps) {
    return (
        <ButtonStructure className={className} onClick={onClick} disabled={isLoading || disabled} type={type || "button"} variant={variant}>
            {isLoading ? (
                <>
                    <Loader2 className="h-4 w-4 animate-spin" data-testid="loading-img" />
                </>
            ) : (
                children
            )}
        </ButtonStructure>
    );
}
