import { createContext, useContext, useEffect, useState } from "react";

type PackageDialog = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PackageDialogContext = createContext<PackageDialog>({} as PackageDialog);

export function usePackageDialogContext() {
    const packageDialog = useContext(PackageDialogContext);

    if (packageDialog === undefined) {
        throw new Error("packageDialog must be used to with a packageDialogContext");
    }

    return packageDialog;
}
